import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { apiClients, makeClientQueryFn } from './lib'

import { DerivedInsightImportance, InsightOutputUnit, MetricExample, Nullable, ReportParams, SeriesParams } from '@percept/types'


export type InsightUnitsByImportance = Record<DerivedInsightImportance, InsightOutputUnit[]>

export const useInsightUnitsByImportance = ({
  series_id,
  report_id,
  result_id,
  ...options
}: (
  Nullable<SeriesParams & ReportParams>
  & Partial<Nullable<{ result_id: string }>>
  & Partial<UseQueryOptions<InsightUnitsByImportance>>
)): UseQueryResult<InsightUnitsByImportance> => {
  return useQuery({
    queryKey: ['insightsByImportance', report_id, result_id],
    queryFn: makeClientQueryFn(
      apiClients.core,
      {
        url: `/report-series/${series_id}/reports/${report_id}/insight-units-by-importance`,
        params: {
          ...(result_id && { result_id }),
        },
      }
    ),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: Infinity,
    enabled: !!(options.enabled !== false && series_id && report_id),
    ...options,
  })
}


export const useLatestInsightUnitsByImportance = ({
  series_id,
  ...options
}: (
  Nullable<SeriesParams>
  & Partial<UseQueryOptions<InsightUnitsByImportance>>
)): UseQueryResult<InsightUnitsByImportance> => {
  return useQuery({
    queryKey: ['insightsByImportance', series_id, 'latest'],
    queryFn: makeClientQueryFn(
      apiClients.core,
      {
        url: `/report-series/${series_id}/insight-units-by-importance/latest`,
      }
    ),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: Infinity,
    enabled: !!(options.enabled !== false && series_id),
    ...options,
  })
}


export const useInsightOutputUnitResultExamples = ({
  series_id,
  insight_output_unit_result_id,
  ...options
}: (
  SeriesParams
  & {
    insight_output_unit_result_id: string
  }
  & Partial<UseQueryOptions<MetricExample[]>>
)): UseQueryResult<MetricExample[]> => {
  return useQuery({
    queryKey: ['insightOutputUnitResults', insight_output_unit_result_id, 'entities'],
    queryFn: makeClientQueryFn<MetricExample[]>(
      apiClients.core,
      {
        url: `/report-series/${series_id}/insight-output-unit-results/${insight_output_unit_result_id}/entities`,
      }
    ),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: Infinity,
    ...options,
  })
}
