import React, { useEffect, useMemo, useState } from 'react'

import {
  Alert,
  BackdropLoader,
  Box,
  ButtonProps,
  ProviderLogo,
  ReportRange,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
} from '@percept/mui'

import { ArrowDropDown, OpenInNew } from '@percept/mui/icons'

import { Link } from 'react-router-dom'

import Series from './Series'

import { PillarScores } from './PillarScores'
import { PillarScoresDownload } from './PillarScoresDownload'

import { InsightsReport } from 'components/InsightsReport'
import { InsightsByImportance } from '@percept/app-components'

import {
  useInsightUnitsByImportance,
  useLatestInsightUnitsByImportance,
  useReportSeries,
  useReportSeriesPillarScores,
  useReportSeriesReports,
} from '@percept/hooks'

import { useActivePlatformUnit } from 'components/Organisation'

import { get, some, sortBy } from 'lodash-es'

import { ReportProviderV2, ReportSeries, SeriesParams, StructuralReport } from '@percept/types'
import { providerLabelMap } from '@percept/constants'


type SeriesInsightsViewProps = (
  SeriesParams
  & {
    provider: ReportProviderV2
    activeReportId: string | null
    setActiveReportId: (value: string) => void
  }
)


const SeriesWithInsightsByImportance = ({
  series_id,
  provider,
  activeReportId,
  setActiveReportId,
}: SeriesInsightsViewProps): JSX.Element => {

  const [reports] = useReportSeriesReports({ series_id })
  const reportsWithResults = sortBy(
    reports.data && reports.data.filter( r => !!r.results.length ),
    'end'
  ).reverse()

  const reportId = activeReportId || get(reportsWithResults, [0, 'id'], null)

  const latestInsightsByImportance = useLatestInsightUnitsByImportance({
    series_id,
    enabled: !activeReportId,
  })

  const historicInsightsByImportance = useInsightUnitsByImportance({
    series_id,
    report_id: reportId,
    enabled: !!reportId,
  })


  const activeReport = reportsWithResults.find( r => r.id === reportId )

  const selectLabel = activeReport && (
    <ReportRange
      disableTypography
      start={activeReport.start}
      end={activeReport.end} />
  )

  const buttonProps: ButtonProps = {
    variant: 'contained',
    color: 'secondary',
    size: 'small',
  }

  const secondaryTitleContent = (
    reportsWithResults.length === 1 ? (
      <RoundedPlainTextButton
        {...buttonProps}>
        { selectLabel }
      </RoundedPlainTextButton>
    ) : reportsWithResults.length ? (
      <RoundedPlainTextButtonMenu
        TriggerProps={{
          ...buttonProps,
          endIcon: <ArrowDropDown />,
        }}
        value={activeReportId}
        label={selectLabel}
        options={
          reportsWithResults.map( r => ({
            value: r.id,
            label: <ReportRange disableTypography start={r.start} end={r.end} />
          }))
        }
        onChange={(e, value): void => {
          setActiveReportId(value)
        }} />
    ) : null
  )

  const activeQuery = activeReportId ? historicInsightsByImportance : latestInsightsByImportance

  return (
    <Box p={3} pt={5}>
      { activeQuery.error ? (
        <Alert variant='info' p={3}>
          Insights could not be loaded
        </Alert>
      ) : !activeQuery.data ? (
        <BackdropLoader />
      ) : (
        <InsightsByImportance
          series_id={series_id}
          report_id={reportId}
          provider={provider}
          insightsByImportance={activeQuery.data}
          secondaryTitleContent={secondaryTitleContent}
          titleContentRight={
            reportId && (
              <Link
                to={`/series/${series_id}/reports/${reportId}`}>
                <RoundedPlainTextButton
                  variant='contained'
                  size='small'
                  color='secondary'
                  startIcon={<OpenInNew />}>
                  View Detailed Report
                </RoundedPlainTextButton>
              </Link>
            )
          } />
      )}
    </Box>
  )
}


const SeriesWithInsights = ({
  series_id,
  provider,
  activeReportId,
  setActiveReportId,
}: SeriesInsightsViewProps): JSX.Element => {

  const [pillarScores] = useReportSeriesPillarScores({ series_id })

  const platformUnit = useActivePlatformUnit()

  return (
    <InsightsReport
      key={series_id}
      series_id={series_id}
      title={
        <ProviderLogo
          size={1.75}
          units='rem'
          provider={provider} />
      }
      activeReportId={activeReportId}
      setActiveReportId={setActiveReportId}
      insights_report_type='primary'
      showReportLink={true}
      showSubTypeOptions={true}
      gridInserts={
        (pillarScores.data && platformUnit) ? [
          <>
            <Box
              display='flex'
              mb={2}
              justifyContent='end'>
              <PillarScoresDownload
                pillarScores={pillarScores.data}
                filename={
                  [
                    platformUnit.name,
                    providerLabelMap[provider],
                    'Pillar Scores',
                  ].join(' - ')
                }
                provider={provider} />

            </Box>
            <PillarScores
              key={series_id}
              provider={provider}
              scores={pillarScores.data} />
          </>
        ] : undefined
      } />
  )
}


const hasPrimaryInsightReports = (
  series: ReportSeries,
  reports: StructuralReport[]
): boolean => {
  const latestReport = sortBy(reports.filter( r => !!r.results.length), 'end').reverse()[0]
  return Boolean(
    series.output_config.insights_reports
    && series.output_config.insights_reports.PRIMARY
    && latestReport
    && some(latestReport.results, res => res.result_type === 'INSIGHTS_REPORT' && res.result_subtype === 'PRIMARY' )
  )
}


export const SeriesViewManager = ({ series_id }: SeriesParams): JSX.Element => {

  const [series] = useReportSeries({ series_id })

  const [reports] = useReportSeriesReports({ series_id })

  const [report_id, setReportId] = useState<string | null>(null)

  // NOTE - by importance view is currently disabled for Vodafone
  const enableImportanceView = process.env.APP === 'dashboard'

  const latestInsightsByImportance = useLatestInsightUnitsByImportance({
    series_id,
    enabled: !report_id && enableImportanceView,
  })

  const historicInsightsByImportance = useInsightUnitsByImportance({
    series_id,
    report_id,
    enabled: !!report_id && enableImportanceView,
  })

  const activeByImportanceQuery = report_id ? historicInsightsByImportance : latestInsightsByImportance

  useEffect(() => {
    if( reports.data && report_id ){
      const match = reports.data.find( r => r.id === report_id )
      if( !match ){
        setReportId(null)
      }
    }
  }, [reports.data, report_id])

  const hasInsights = useMemo(() => {
    if( !(series.data && reports.data) ){
      return null
    }
    return hasPrimaryInsightReports(series.data, reports.data)
  }, [series.data, reports.data])

  if( enableImportanceView && activeByImportanceQuery.data && series.data ){
    return (
      <SeriesWithInsightsByImportance
        key={series_id}
        series_id={series_id}
        provider={series.data.provider.slug}
        activeReportId={report_id}
        setActiveReportId={setReportId} />
    )
  }

  if( !(series.data && reports.data) || activeByImportanceQuery.isLoading ){
    return <BackdropLoader />
  }

  if( hasInsights && (enableImportanceView ? activeByImportanceQuery.error : true) ){
    return (
      <SeriesWithInsights
        key={series_id}
        series_id={series_id}
        provider={series.data.provider.slug}
        activeReportId={report_id}
        setActiveReportId={setReportId} />
    )
  }

  return (
    <Series series_id={series_id} />
  )
}
