import React, { useState } from 'react'

import {
  Box,
  ButtonPopover,
  ButtonPopoverProps,
  CheckboxGroup,
  Divider,
  RoundedPlainTextButton,
} from '@percept/mui'
import { ArrowDropDown } from '@percept/mui/icons'

import { intersection } from 'lodash-es'

import { deslugify } from '@percept/utils'

import { getPrimaryApplicationLabel, getSecondaryApplicationLabel, PrimaryApplication, SecondaryApplication } from '@percept/app-components'


export type MultiApplicationSelectProps = {
  primaryApplications: PrimaryApplication[] | null
  secondaryApplications: SecondaryApplication[] | null
  primaryApplicationOptions: PrimaryApplication[]
  secondaryApplicationOptions: SecondaryApplication[]
  onChange: (value: {
    primaryApplications: PrimaryApplication[]
    secondaryApplications: SecondaryApplication[]
  }) => void
  ButtonPopoverProps?: Partial<Omit<ButtonPopoverProps, 'children'>>
}


export const MultiApplicationSelect = ({
  primaryApplications,
  secondaryApplications,
  primaryApplicationOptions,
  secondaryApplicationOptions,
  onChange,
  ButtonPopoverProps,
}: MultiApplicationSelectProps): JSX.Element => {

  const combinedValue = [
    ...(primaryApplications || []),
    ...(secondaryApplications || []),
  ]
  const [localValue, setLocalValue] = useState<string[]>(combinedValue)

  const areFiltersApplied = (
    (combinedValue || []).sort().join() === localValue.sort().join()
  )

  const isEmptyValue = localValue.length === 0

  return (
    <ButtonPopover
      variant='contained'
      endIcon={<ArrowDropDown />}
      ButtonComponent={RoundedPlainTextButton}
      buttonContent='Select Applications'
      onClose={(reason): void => {
        if( reason ){
          setLocalValue(combinedValue)
        }
      }}
      {...ButtonPopoverProps}>
      { ({ onClose }): JSX.Element => (
        <Box p={2}>
          <Box ml={0}>
            <CheckboxGroup
              name='Applications'
              value={localValue}
              options={
                [
                  ...primaryApplicationOptions.map( value => ({
                    value,
                    label: getPrimaryApplicationLabel(value),
                  })),
                  ...secondaryApplicationOptions.map( value => ({
                    value,
                    label: getSecondaryApplicationLabel(value),
                  }))
                ]
              }
              onChange={setLocalValue} />
          </Box>
          <Box maxWidth='100%' flexBasis='100%' mt={1} px={0}>
            <Divider />
          </Box>
          <Box pt={2} textAlign='right'>
            <RoundedPlainTextButton
              variant='contained'
              color='secondary'
              size='small'
              disabled={areFiltersApplied || isEmptyValue}
              onClick={(): void => {
                const primaryApplications = intersection(
                  primaryApplicationOptions,
                  localValue
                ) as PrimaryApplication[]
                const secondaryApplications = intersection(
                  secondaryApplicationOptions,
                  localValue
                ) as SecondaryApplication[]
                onChange({ primaryApplications, secondaryApplications })
                onClose()
              }}>
              { isEmptyValue ? 'Select An Application' : areFiltersApplied ? 'Filters Applied' : 'Apply Filters'}
            </RoundedPlainTextButton>
          </Box>
        </Box>
      )}
    </ButtonPopover>
  )
}
