import React, { Fragment, useContext, useMemo } from 'react'

import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CardStrip,
  Divider,
  Grid,
  Metric,
  ErrorMetric,
  CopyToClipboard,
  Health,
  PercentageBlob,
  PercentageBlobProps,
  resolveAttributeMetric,
  useAppTheme,
  Alert,
  AppTheme,
  Loader,
  CardProps,
} from '@percept/mui'

import { StructuralEntityList } from './StructuralEntityList'

import { DSLRenderer } from './DSLComponents'

import { flatten, get, pick, sortBy } from 'lodash-es'

import { deslugify } from '@percept/utils'

import { useInsightOutputUnitResultExamples } from '@percept/hooks'

import {
  getItemRendererDependencies,
  ItemParent,
  ItemRenderer,
  parseRelatedMetric,
} from './lib'

import {
  InsightsReportType,
  InsightsReportSectionType,
  InsightsReportOutputUnitType,
  InsightsReportFindingType,
  InsightsReportRelatedMetric,
  Metric as MetricType,
  ReportEntityPayload,
  DateType,
  MetricDisplayType,
} from '@percept/types'

import { InsightsReportContext } from './context'


export const HealthBlob = ({
  value,
  ...props
}: Partial<Omit<PercentageBlobProps, 'value'>> & {
    value: number
  }
): JSX.Element => {
  const theme = useAppTheme()
  const color = theme.chart.healthColourScale(value)
  const textColor = theme.palette.background.paper
  return (
    <PercentageBlob
      value={value * 100}
      size={48}
      fontSize={14}
      color={color}
      textColor={textColor}
      {...props} />
  )
}


export const resolveMetric = (
  id: string,
  reportEntity: ReportEntityPayload,
): MetricType | null => {
  if( reportEntity.metrics[id] ){
    return reportEntity.metrics[id]
  }
  const resolved = resolveAttributeMetric({
    id,
    attributes: get(reportEntity.derived, 'attributes', {}),
    performanceAttributes: get(reportEntity.derived, 'performance', {}),
  })
  if( !resolved ){
    console.group('Could not resolve attribute metric', id)
    console.log('Derived attributes -> ', reportEntity.derived)
    console.groupEnd()
  }
  return resolved
}


export const InsightsReportMetric: ItemRenderer<{
  metric: InsightsReportRelatedMetric
  currency?: string | null
  displayType?: MetricDisplayType
  CardProps?: CardProps
}> = ({
  metric,
  reportEntity,
  metricMetadata,
  setActiveMetric,
  currency,
  displayType = 'SUMMARY',
  CardProps = {},
}) => {
  const metadataEntry = metricMetadata[metric.metric_id]
  if( !metadataEntry ){
    return (
      <ErrorMetric>
        Warning - no metadata entry for
        <span style={{width: '1em'}} />
        <CopyToClipboard value={metric.metric_id} />
      </ErrorMetric>
    )
  }
  const resolvedMetric = resolveMetric(metric.metric_id, reportEntity)
  return (
    <Metric
      MetricCardProps={{
        CardProps: {
          variant: process.env.APP === 'vodafone' ? 'elevation' : 'outlined',
          ...CardProps
        }
      }}
      id={metric.metric_id}
      metric={resolvedMetric}
      dimensionOptions={[metric.dimension || 'count']}
      currency={currency}
      {...pick(metadataEntry, ['title', 'descriptions', 'display_options'])}
      displayType={displayType}
      onSegmentClick={setActiveMetric}
      setActiveMetric={setActiveMetric} />
  )
}


export const InsightsReportFindingSummary: ItemRenderer<
  InsightsReportFindingType & {
    appTheme: AppTheme
    parent: ItemParent
    BoxProps?: BoxProps
  }
> = ({
  appTheme,
  health,
  text,
  BoxProps = {},
  currency,
}) => {
  return (
    <Box
      display='flex'
      alignItems='flex-start'
      maxWidth='45rem'
      fontSize={16}
      fontWeight={appTheme.typography.fontWeightMedium}
      {...BoxProps}>
      { health !== null && (
        <Box mr={2}>
          <HealthBlob
            value={health}
            size={40}
            fontSize={12} />
        </Box>
      )}
      <Box
        display='flex'
        flexDirection='column'
        alignSelf='stretch'
        justifyContent='center'
        lineHeight={1.35}>
        <DSLRenderer
          appTheme={appTheme}
          text={text}
          currency={currency} />
      </Box>
    </Box>
  )
}


export const InsightsReportFinding: ItemRenderer<
  InsightsReportFindingType & {
    parent: ItemParent
  }
> = (props) => {
  const {
    id,
    health,
    text,
    entities,
    related_metrics,
    parent,
    potentialEfficiencyConfigurations,
    total_entities,
  } = props

  const { series_id, version } = useContext(InsightsReportContext)

  const { data, isLoading } = useInsightOutputUnitResultExamples({
    series_id,
    insight_output_unit_result_id: id as string,
    enabled: version >= 2 && !!(total_entities),
  })

  const examples = entities || (data && data.length ? data : null)

  return (
    <Card>
      <CardStrip health={props.health} color='health' animate={false} />

      <CardContent>
        <InsightsReportFindingSummary
          {...props} />

        { isLoading && (
          <Loader preset='fullsize' minHeight='8rem' />
        )}
        { examples && (
          <Box pl={3} py={3}>
            <StructuralEntityList
              entities={examples}
              toggleDetail={(): void => {
                props.setActiveOutput({
                  id,
                  entities: examples,
                  text,
                  health,
                  title: parent.text,
                })
              }} />
          </Box>
        )}

        { related_metrics && !!(related_metrics.length) && (
          <Box mt={4}>
            <Grid container spacing={3}>
              { related_metrics.map( metric => {
                metric = parseRelatedMetric(metric)
                return (
                  <Grid item key={metric.metric_id}>
                    <InsightsReportMetric
                      metric={metric}
                      {...getItemRendererDependencies(props)} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        )}
      </CardContent>

    </Card>
  )
}


export const InsightsReportOutputUnitRenderer: ItemRenderer<
  Pick<InsightsReportOutputUnitType, 'currency' | 'text' | 'health' | 'findings'> & {
    parent: ItemParent
    summary: boolean
    BoxProps?: BoxProps
  }
> = ({
  findings,
  summary,
  BoxProps = {},
  potentialEfficiencyConfigurations,
  ...props
}) => {
  const Renderer = summary ? InsightsReportFindingSummary : InsightsReportFinding
  return (
    <Box {...BoxProps}>
      <Grid container spacing={3}>
        { findings.map( (finding, i) => (
          <Grid
            key={`finding-${i}`}
            item
            xs={12}
            {...(!summary && {
              md: 6,
              lg: 4,
              xl: 3,
            } || {})}>
            <Renderer
              parent={pick(props, ['text', 'health'])}
              id={finding.id}
              {...getItemRendererDependencies(props)}
              {...finding}
              currency={finding.currency || props.currency} />
            {/* { !!(
              finding.id
              && finding.health !== null
              && finding.health < 1
              && potentialEfficiencyConfigurations
              && potentialEfficiencyConfigurations[finding.id]
              && potentialEfficiencyConfigurations[finding.id].potential_efficiency_ratio !== null
            ) && (
              <Box fontSize={14} fontWeight={700} mt={2} pl={7} textAlign='left'>
                Wastage { percentageFormatter(
                  Number(potentialEfficiencyConfigurations[finding.id].potential_efficiency_ratio) * 100
                ) }
              </Box>
            )} */}
            { summary && i < (findings.length - 1) && (
              <Box mt={3}>
                <Divider />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}


export const InsightsReportOutputUnit: ItemRenderer<
  InsightsReportOutputUnitType & {
    parent: ItemParent
    summary: boolean
    BoxProps?: Partial<BoxProps>
  }
> = ({
  entities,
  related_metrics,
  findings,
  parent,
  summary,
  BoxProps = {},
  ...props
}) => {
  return (
    <Box
      {...BoxProps}>
      { !!(entities && entities.length) && (
        <Box pl={3} py={3}>
          <StructuralEntityList
            entities={entities}
            toggleDetail={(): void => {
              props.setActiveOutput({
                id: props.id,
                entities,
                text: props.text,
                health: props.health,
                title: parent.text,
              })
            }} />
        </Box>
      )}

      <InsightsReportOutputUnitRenderer
        summary={summary}
        parent={pick(props, ['text', 'health'])}
        findings={findings}
        {...props} />

      { related_metrics && !!(related_metrics.length) && (
        <Box mt={6}>
          <Divider />
          <Box mt={4} mb={2} fontSize={12} fontWeight={props.appTheme.typography.fontWeightBold} color='text.hint'>
            Related Metrics
          </Box>
          <Grid container spacing={3} alignItems='center' wrap='wrap'>
            { related_metrics.map( metric => {
              metric = parseRelatedMetric(metric)
              return (
                <Grid item key={metric.metric_id}>
                  <InsightsReportMetric
                    metric={metric}
                    {...getItemRendererDependencies(props)} />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      )}
    </Box>
  )
}



export const InsightsReportOutputUnitDetail: ItemRenderer<
  InsightsReportOutputUnitType & {
    parent: ItemParent
  }
> = (props) => {
  return (
    <Fragment>
      <Box
        display='flex'
        alignItems='center'
        pb={2}
        fontSize={22}
        fontWeight={props.appTheme.typography.fontWeightBold}>
        { props.text }

        { props.health !== null && (
          <Box ml={1}>
            <Health
              animate={false}
              value={props.health}
              fontSize='26px' />
          </Box>
        )}
      </Box>
          
      <InsightsReportOutputUnit
        summary={false}
        BoxProps={{mt: 5}}
        {...props} />
    </Fragment>
  )
}


export const InsightsReportOutputUnitWrap: ItemRenderer<
  InsightsReportOutputUnitType & {
    parent: ItemParent
    summary: boolean
  }
> = ({ summary, ...props }) => {
  const Renderer = summary ? InsightsReportOutputUnitRenderer : InsightsReportOutputUnit
  return (
    <Card
      onClick={(): void => {
        props.setActiveOutputUnit(props)
      }}
      style={{
        cursor: 'pointer',
      }}>
      <CardStrip health={props.health} color='health' animate={false} />

      <CardContent>
        <Box
          display='flex'
          alignItems='center'
          fontSize={17}
          py={0.5}
          fontWeight={props.appTheme.typography.fontWeightBold}>
          { props.text }

          { props.health !== null && (
            <Box ml={1}>
              <Health
                animate={false}
                value={props.health}
                fontSize='20px' />
            </Box>
          )}
        </Box>

        <Box my={2}>
          <Divider />
        </Box>

        <Box mt={4}>
          <Renderer
            summary={summary}
            {...props} />
        </Box>
      </CardContent>
    </Card>
  )
}

export const InsightsReportSection: ItemRenderer<
  InsightsReportSectionType & {
    id?: string | null
    showHealth: boolean
  }
> = ({
  /* eslint-disable react/prop-types */
  id,
  name,
  health,
  members,
  showHealth,
  ...props
  /* eslint-enable react/prop-types */
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          display='flex'
          alignItems='center'
          py={1}
          fontSize={20}
          fontWeight={props.appTheme.typography.fontWeightBold}>
          { name }

          { showHealth && health !== null && (
            <Box ml={1}>
              <Health
                animate={false}
                value={health}
                fontSize='22px' />
            </Box>
          )}
        </Box>
      </Grid>

      { members.map( (outputUnit, i) => (
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          xl={3}
          key={`output-unit-${i}`}>
          <InsightsReportOutputUnitWrap
            summary
            parent={{
              text: name,
              health,
            }}
            id={`${String(id || i)}-${i}`}
            {...getItemRendererDependencies(props)}
            {...outputUnit} />
        </Grid>
      ))}
    </Grid>
  )
}


export const PriorityFinding: ItemRenderer<
  InsightsReportFindingType & {
    appTheme: AppTheme
    onClick?: () => void
  }
> = ({
  /* eslint-disable react/prop-types */
  appTheme,
  health,
  text,
  currency,
  onClick,
  entities,
  total_entities,
  /* eslint-enable react/prop-types */
}) => {
  const hasEntities = !!(entities && entities.length) || !!total_entities
  return (
    <Card
      onClick={hasEntities ? onClick : undefined}
      style={{
        cursor: hasEntities ? 'pointer' : 'default',
      }}>
      <CardStrip health={health} color='health' animate={false} />

      <CardContent>
        <Box
          display='flex'
          alignItems='flex-start'
          maxWidth='45rem'
          fontSize={16}
          fontWeight={appTheme.typography.fontWeightMedium}>
          { health !== null && (
            <Box display='flex' mr={2}>
              <HealthBlob
                value={health}
                size={40}
                fontSize={12} />
            </Box>
          )}
          <Box
            display='flex'
            flexDirection='column'
            alignSelf='stretch'
            justifyContent='center'
            lineHeight={1.35}>
            <DSLRenderer
              appTheme={appTheme}
              text={text}
              showRecommendations={false}
              currency={currency} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}


export const EmptyInsightsReportMessage = (
  { insightsReportType }: { insightsReportType: string }
): JSX.Element => {
  const label = deslugify(insightsReportType)
  return (
    <Alert
      marginY={8}
      marginX='auto'
      paddingX={5}
      paddingY={3}
      textAlign='center'
      variant='info'
      header={`No ${label} Activity Detected`}
      message={
        <p>
          No {label} activity was detected for this date range.
        </p>
      } />
  )
}


export const InsightsReportPriorityView: ItemRenderer<
  InsightsReportType
> = ({
  /* eslint-disable react/prop-types */
  sections,
  setActiveOutput,
  ...props
  /* eslint-enable react/prop-types */
}) => {
  const priorityFindings = useMemo(() => {
    const findings = flatten(
      sections.map( s => (
        flatten(
          s.members.map( m => (
            flatten(m.findings)
          ))
        )
      ))
    ).filter( f => f.health !== null )

    return sortBy(findings, f => f.health)
  }, [sections])

  return (
    <Grid container spacing={4}>
      { priorityFindings.map( (finding, i) => (
        <Grid
          key={`priority-finding-${i}`}
          item
          xs={12}
          md={6}
          lg={4}
          xl={3}>
          <PriorityFinding
            {...props}
            {...finding}
            setActiveOutput={setActiveOutput}
            onClick={(): void => {
              setActiveOutput({
                id: finding.id || finding.text || `priority-finding-${i}`,
                entities: finding.entities,
                total_entities: finding.total_entities,
                text: finding.text,
                health: finding.health,
                title: finding.title || 'Priority View',
              })
            }} />
        </Grid>
      ))}
    </Grid>
  )

}


export const InsightsReportComponent: ItemRenderer<
  InsightsReportType & {
    start: DateType
    end: DateType
    showSectionHealth: boolean
    BoxProps?: BoxProps
  }
> = ({
  /* eslint-disable react/prop-types */
  sections,
  BoxProps = {},
  showSectionHealth,
  ...props
  /* eslint-enable react/prop-types */
}) => {

  const filteredSections = sections.filter( s => !!s.members.length )

  return (
    <Box display='flex' justifyContent='center' pb={3} {...BoxProps}>

      <Grid container spacing={3}>
        { filteredSections.map( (section, i) => (
          <Fragment
            key={`output-unit-${i}`}>
            <Grid
              item
              xs={12}
              key={`output-unit-${i}`}>
              <InsightsReportSection
                key={`section-${i}`}
                id={String(i)}
                showHealth={showSectionHealth}
                {...getItemRendererDependencies(props)}
                {...section} />
            </Grid>

            { i < (filteredSections.length - 1) && (
              <Grid item xs={12}>
                <Box mt={3} mb={2}>
                  <Divider />
                </Box>
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    </Box>
  )
}
