import React from 'react'

import { darken, lighten } from '@material-ui/core'

import { VodafoneLogo } from '../components/Logos'

import { makeBrandedAppTheme } from './brandedAppThemes'


const brandRed = 'rgb(230,0,0)'

const brandSecondary = '#007C92'

const primaryColor = brandRed

export const vodafonePalette = {
  primaryBackground: '#efefef',
  secondaryBackground: '#fff',
  slateGrey: 'rgb(37,40,43)',
  turquoise: 'rgb(0,124,146)',
  aquaBlue: 'rgb(0,176,202)',
  aubergine: 'rgb(94,39,80)',
  redViolet: 'rgb(156,42,160)',
  springGreen: 'rgb(168,180,0)',
  freshOrange: 'rgb(235,151,0)',
  lemonYellow: 'rgb(254,203,0)',
  grey: 'rgb(74,77,78)',
  red: brandRed,
}

const genericChartColourKeys: (keyof (typeof vodafonePalette))[] = [
  'red',
  'turquoise',
  'aubergine',
  'springGreen',
  'freshOrange',
  'redViolet',
  'aquaBlue',
  'lemonYellow',
]

const genericChartColours = [
  ...genericChartColourKeys.map( k => vodafonePalette[k] ),
  ...genericChartColourKeys.map( k => darken(vodafonePalette[k], 0.3) ),
  ...genericChartColourKeys.map( k => lighten(vodafonePalette[k], 0.35) ),
]


export const vodafoneAppTheme = makeBrandedAppTheme({
  branding: {
    label: 'Vodafone',
    // eslint-disable-next-line
    LogoComponent: (props) => (
      <VodafoneLogo
        {...props}
        size={28}
        units='px'
        height={16}
        overflow='visible' />
    ),
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    type: 'light',
    neutral: {
      main: vodafonePalette.grey,
    },
    primary: {
      main: vodafonePalette.red,
      contrastText: '#FFF',
    },
    secondary: {
      main: brandSecondary,
      contrastText: '#FFF',
    },
    action: {
      active: '#000',
      disabled: 'rgb(150, 150, 150)',
      disabledBackground: 'rgb(240, 240, 240)',
    },
    background: {
      default: vodafonePalette.primaryBackground,
      paper: vodafonePalette.secondaryBackground,
    },
    text: {
      primary: '#000',
      secondary: 'rgba(0, 0, 0, 0.65)',
      hint: '#CCC',
    },
    error: {
      light: lighten(vodafonePalette.red, 0.1),
      main: vodafonePalette.red,
      dark: darken(vodafonePalette.red, 0.1),
      contrastText: '#FFF',
    },
    warning: {
      light: vodafonePalette.lemonYellow,
      main: darken(vodafonePalette.lemonYellow, 0.15),
      dark: darken(vodafonePalette.lemonYellow, 0.25),
      contrastText: '#FFF',
    },
    success: {
      light: vodafonePalette.springGreen,
      main: darken(vodafonePalette.springGreen, 0.1),
      dark: darken(vodafonePalette.springGreen, 0.2),
      contrastText: '#FFF',
    },
    info: {
      light: lighten(vodafonePalette.turquoise, 0.1),
      main: vodafonePalette.turquoise,
      dark: darken(vodafonePalette.turquoise, 0.1),
      contrastText: '#FFF',
    },
    channel: {
      search: {
        light: lighten(vodafonePalette.aquaBlue, 0.1),
        main: vodafonePalette.aquaBlue,
        dark: darken(vodafonePalette.aquaBlue, 0.1),
        contrastText: '#FFF',
      },
      social: {
        light: lighten(vodafonePalette.redViolet, 0.1),
        main: vodafonePalette.redViolet,
        dark: darken(vodafonePalette.redViolet, 0.1),
      },
      programmatic: {
        light: lighten(vodafonePalette.freshOrange, 0.1),
        main: vodafonePalette.freshOrange,
        dark: darken(vodafonePalette.freshOrange, 0.1),
        contrastText: '#FFF',
      },
    },
  },
  chart: {
    healthColourStops: [
      vodafonePalette.red,
      vodafonePalette.lemonYellow,
      darken(vodafonePalette.springGreen, 0.05),
    ],
    informationalColourStops: [
      vodafonePalette.aquaBlue,
      vodafonePalette.aubergine,
    ],
    genericColourStops: genericChartColours,
    grid: {
      stroke: 'rgb(220, 220, 220)',
    },
    tooltip: {
      valueColor: '#000',
      valueBackgroundColor: 'rgb(240, 240, 240)',
      secondaryContentColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  typography: {
    fontFamily: 'Vodafone, Helvetica Neue, Arial, Helvetica, sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  appComponents: {
    appBarTabIndicator: {
      colorPrimary: {
        backgroundColor: '#FFF',
      },
      colorSecondary: {
        backgroundColor: '#FFF',
      }
    },
    authenticator: {
      root: {
        // backgroundColor: 'rgba(255, 255, 255, 0.75)',
      },
      heading: {
        fontSize: 24,
        fontWeight: 500,
      },
      input: {
        color: '#000',
        backgroundColor: '#FFF',
        borderRadius: 6,
        fontWeight: 500,
      }
    },
    contrastPanel: {
      root: {
        color: '#FFF',
        backgroundColor: vodafonePalette.grey,
        borderRadius: 6,
      },
    },
    performanceTable: {
      cellCompact: {
        fontSize: 14,
        '@media (min-width: 1500px)': {
          fontSize: 14,
        },
        '@media (max-width: 600px)': {
          fontSize: 13,
        },
        '@media (min-width: 1920px)': {
          fontSize: 15,
        },
      },
    },
    reportDashboard: {
      secondaryHealth: {
        backgroundColor: '#FFF',
      },
    },
    table: {
      footer: {
        color: '#FFF',
        backgroundColor: vodafonePalette.grey,
        borderColor: '#4a4949',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': 'Vodafone',
        },
      },
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        color: '#000',
        backgroundColor: '#FFF !important',
      },
      colorPrimary: {
        color: '#FFF',
        backgroundColor: `${vodafonePalette.red} !important`,
      },
      colorSecondary: {
        color: '#FFF',
        backgroundColor: `${vodafonePalette.red} !important`,
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#FFF',
      },
      containedSecondary: {
        color: '#FFF',
      },
    },
    MuiCard: {
      root: {
        background: vodafonePalette.secondaryBackground,
      },
    },
    MuiChip: {
      outlined: {
        color: '#000',
      },
      colorPrimary: {
        color: '#FFF',
      },
      colorSecondary: {
        color: '#FFF',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      },
    },
    /* @material-ui/pickers */
    
    MuiPickersToolbar: {
      toolbar: {
        color: '#FFF',
        backgroundColor: vodafonePalette.lemonYellow,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: 'rgba(0,0,0,0.85)',
        fontWeight: 700,
      },
      switchHeader: {
        color: '#000',
      },
      iconButton: {
        color: 'rgba(0,0,0,0.85)',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: primaryColor,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: primaryColor,
      },
    },
    MuiPickersClockPointer:{
      pointer: {
        backgroundColor: primaryColor,
      },
      noPoint: {
        backgroundColor: primaryColor,
      },
      thumb: {
        backgroundColor: primaryColor,
        border: `14px solid ${primaryColor}`,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: primaryColor,
        '&:hover': {
          backgroundColor: primaryColor,
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: `${primaryColor} !important`,
          },
        },
      },
      dayDisabled: {
        color: 'rgba(0,0,0,0.35)',
      },
    },
    MuiPickersYear: {
      yearDisabled: {
        color: 'rgba(0,0,0,0.35)',
      },
    },
    MuiTableCell: {
      root: {
        color: 'inherit',
        fontSize: 13,
      },
      sizeSmall: {
        fontSize: 13,
        '@media (min-width: 1500px)': {
          fontSize: 14,
        },
        '@media (max-width: 600px)': {
          fontSize: 12,
        },
        '@media (min-width: 1920px)': {
          fontSize: 15,
        },
      },
    },

    // MuiToolbar: {
    //   root: {
    //     minHeight: 0,
    //   },
    //   regular: {
    //     minHeight: 0,
    //   },
    //   gutters: {
    //     paddingLeft: '0.5rem',
    //     paddingRight: '0.5rem',
    //   }
    // },
  },
})
