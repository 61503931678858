import React, { useMemo, useState } from 'react'

import { Box, BoxProps, Chip, Divider, Typography } from '@material-ui/core'

import { ButtonPopover } from '../Popovers'

import { RoundedPlainTextButton } from '../Buttons'

import { FilterList } from '../../icons'

import { makeAppStyles } from '../../themes'

import { isEqual, xor } from 'lodash-es'


type NullableValues<T extends object> = {
  [K in keyof T]: T[K] | null 
}


export type FilterPanelRenderProps<T extends object> = {
  values: NullableValues<T>
  updateValues: (partialUpdate: Partial<NullableValues<T>>) => void
}

export type FilterPanelProps<T extends object> = {
  values: NullableValues<T>
  name?: string
  // NOTE - `displayConfig` is partial, to enable consuming code
  // to place arbitrary state in values or model related value state
  // which doesn't require it's own display configuration and may be
  // combined with th display of other values.
  displayConfig: Partial<{
    [Property in keyof T]: {
      label: React.ReactNode
      render: (value: T[Property] extends any[] ? T[Property][number] : T[Property]) => React.ReactNode | null
    }
  }>
  validate?: (values: NullableValues<T>) => boolean
  valueOrder?: (keyof T)[]
  onConfirm: (values: NullableValues<T>) => void
  children: (props: FilterPanelRenderProps<T>) => JSX.Element
} & Omit<BoxProps, 'children'>


const useStyles = makeAppStyles( theme => ({
  editButton: {
    marginLeft: 'auto',
    alignSelf: 'flex-start',
    minWidth: 'fit-content',
  },
  filterDisplay: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(2),
  },
  filterLabel: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
  filterContent: {
    lineHeight: '32px',
  },
  filterChip: {
    marginRight: theme.spacing(1),
    '&:last-of-type': {
      marginRight: 0,
    }
  },
}))


function areFiltersEqual<T extends object>(a: T, b: T): boolean {
  const keys = Object.keys(a) as (keyof T)[]
  for( const key of keys ){
    if( Array.isArray(a[key]) ){
      if( b[key] && isEqual(a[key].sort(), (b[key] as any[]).sort()) ){
        continue
      }
    }
    if( isEqual(a[key], b[key]) ){
      continue
    }
    return false
  }
  return true
}


export function FilterPanel<T extends object>({
  values,
  validate,
  displayConfig,
  valueOrder,
  onConfirm,
  children,
  name = 'Filters',
  ...props
}: FilterPanelProps<T>): JSX.Element {

  const [localValues, setLocalValues] = useState<NullableValues<T>>(values)

  const updateValues = (partialValues: Partial<NullableValues<T>>): void => {
    setLocalValues( prev => ({...prev, ...partialValues}))
  }

  const classes = useStyles()

  const allFiltersApplied = useMemo(() => {
    return areFiltersEqual(values, localValues)
  }, [values, localValues])

  const disabled = allFiltersApplied || (!!validate && !validate(localValues))

  valueOrder = valueOrder || Object.keys(values) as (keyof T)[]

  const elements = valueOrder.reduce( (acc, key) => {
    const display = displayConfig[key]
    if( !display ){
      return acc
    }
    const value = values[key]
    if( !value ){
      return acc
    }
    let content: JSX.Element | null = null
    if( Array.isArray(value) ){
      if( !value.length ){
        return acc
      }
      content = <>
          { value.map( (v, i) => (
            <Chip
              key={`${key as string}-value-${i}`}
              className={classes.filterChip}
              size='small'
              label={display.render(v)}
              onDelete={(): void => {
                const update = {
                  [key]: xor(localValues[key] as [], [v])
                }
                updateValues(update as Partial<T>)
                onConfirm({
                  ...localValues,
                  ...update,
                })
              }} />
          )) }
        </>
    }else{
      content = (
        <Chip
          key={`${key as string}-value`}
          className={classes.filterChip}
          size='small'
          label={display.render(value as any)}
          onDelete={(): void => {
            updateValues({
              [key]: null
            } as Partial<T>)
            onConfirm({
              ...localValues,
              [key]: null,
            })
          }} />
      )
    }
    acc.push(
      <div key={key as string} className={classes.filterDisplay}>
        <Typography className={classes.filterLabel} variant='subtitle1'>{ display.label }</Typography>
        <span className={classes.filterContent}>
          { content }
        </span>
      </div>
    )
    return acc
  }, [] as JSX.Element[])


  return (
    <Box display='flex' flexWrap='nowrap' alignItems='center' {...props}>

      { !elements.length && (
        <Typography color='textSecondary'>No {name} Applied</Typography>
      )}

      { elements }

      <ButtonPopover
        ButtonComponent={RoundedPlainTextButton}
        className={classes.editButton}
        startIcon={<FilterList />}
        variant='contained'
        size='small'
        buttonContent={`Edit ${name}`}>
        { ({ onClose }): JSX.Element => (
          <Box p={2}>
            { children({ values: localValues, updateValues })}
            <Box my={2}>
              <Divider />
            </Box>
            <Box display='flex' justifyContent='flex-end'>
              <RoundedPlainTextButton
                variant='contained'
                onClick={onClose}>
                Cancel
              </RoundedPlainTextButton>
              <Box ml={2}>
                <RoundedPlainTextButton
                  variant='contained'
                  color='primary'
                  disabled={disabled}
                  onClick={(): void => {
                    onConfirm(localValues)
                    onClose()
                  }}>
                  { allFiltersApplied ? `${name} Applied` : `Apply ${name}` }
                </RoundedPlainTextButton>
              </Box>
            </Box>
          </Box>
        )}
      </ButtonPopover>
    </Box>
  )
}
