import React, { useMemo } from 'react'
import { Box, Card, Divider, Typography, makeAppStyles, useAppTheme } from '@percept/mui'
import { ChevronRightThin } from '@percept/mui/icons'
import { round } from 'lodash-es'
import { vodafoneMarkets } from 'vodafoneMarkets'
import { allMarketsROIByIsoCode, roiMarkets } from './dataCache'
import { ChartData, ResponsiveHistogramWithTooltip } from '@percept/mui/charts'


const useStyles = makeAppStyles( theme => ({
  typography: {
    display: 'flex',
    alignItems: 'center',
  },
  separatorIcon: {
    margin: theme.spacing(0, 1),
    fontSize: '0.75em',
    strokeWidth: 2,
    color: 'rgba(0, 0, 0, 0.65)',
  }
}))


const roiFormatter = (value: string | number | null): string => (
  String(round(Number(value), 2))
)

export const MMMAllMarkets = (): JSX.Element => {

  const classes = useStyles()

  const appTheme = useAppTheme()

  const chartData = useMemo(() => {
    const colourScale = appTheme.chart.getOrdinalColourScale(
      vodafoneMarkets.map( m => m.iso_code )
    )

    return roiMarkets.map( market => ({
      value: allMarketsROIByIsoCode[market.iso_code],
      label: market.name,
      color: colourScale(market.iso_code)
    })) as ChartData
  }, [appTheme])

  return (
    <Card elevation={2}>
      <Box mx={1.5} my={2}>
        <Typography className={classes.typography} variant='h4'>
          All Markets
          <ChevronRightThin className={classes.separatorIcon} />
          ROI
        </Typography>
      </Box>

      <Divider />

      <Box p={3} pb={5}>
        <ResponsiveHistogramWithTooltip
          height={400}
          axisText
          grid
          yTickFormatter={roiFormatter}
          data={chartData} />
      </Box>
    </Card>
  )
}

