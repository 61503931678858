import { VodafoneMarket, vodafoneMarkets } from 'vodafoneMarkets'


export type MMMSegment = 'BRAND_OFFLINE' | 'BRAND_ONLINE' | 'PERFORMANCE_OFFLINE' | 'PERFORMANCE_ONLINE' | 'BUSINESS'

export type MMMDataUnit = Record<MMMSegment, number | null>

export type MMMDatasetKey = 'ANNUAL_BASE' | 'ANNUAL_RECO' | 'ANNUAL_L12M'

export type MMMData = Record<MMMDatasetKey, MMMDataUnit>

export type MMMDataWrapper = {
  data: MMMData
  recommendations: string[]
  downloadAssetIdentifier: string
}

export const mmmDataByIsoCode: Record<string, MMMDataWrapper> = {
  AL: {
    recommendations: [
      'Keep high Brand Share',
      'Reduce Performance offline',
      'Potential to gain 135k incremental contracts',
    ],
    data: {
      ANNUAL_BASE: {
        BRAND_OFFLINE: 0.62820171051279,
        BRAND_ONLINE: 0.0759866951155297,
        PERFORMANCE_OFFLINE: 0.223607529888716,
        PERFORMANCE_ONLINE: 0.0441846860123192,
        BUSINESS: 0.0280193784706448,
      },
      ANNUAL_RECO: {
        BRAND_OFFLINE: 0.616221654563178,
        BRAND_ONLINE: 0.087778693259923,
        PERFORMANCE_OFFLINE: 0.163035981155655,
        PERFORMANCE_ONLINE: 0.0769249140802073,
        BUSINESS: 0.0560387569410373,
      },
      ANNUAL_L12M: {
        BRAND_OFFLINE: 0.554360484842849,
        BRAND_ONLINE: 0.103913843507461,
        PERFORMANCE_OFFLINE: 0.293092715114692,
        PERFORMANCE_ONLINE: 0.0472536377714823,
        BUSINESS: 0.00137931876351534,
      }
    },
    downloadAssetIdentifier: 'mmm-al-10-2024',
  },
  // CZ: {
  //   recommendations: [
  //     'Increase Performance and Business Spend',
  //     'Potential to gain 200 incremental contracts',
  //   ],
  //   data: {
  //     ANNUAL_BASE: {
  //       BRAND_OFFLINE: 0.323332922130129,
  //       BRAND_ONLINE: 0.0970712403337474,
  //       PERFORMANCE_OFFLINE: 0.230599431007669,
  //       PERFORMANCE_ONLINE: 0.235027411572829,
  //       BUSINESS: 0.113968994955625,
  //     },
  //     ANNUAL_RECO: {
  //       BRAND_OFFLINE: 0.189585117626595,
  //       BRAND_ONLINE: 0.0488680944986298,
  //       PERFORMANCE_OFFLINE: 0.351024176532022,
  //       PERFORMANCE_ONLINE: 0.182584621426074,
  //       BUSINESS: 0.22793798991668,
  //     },
  //     ANNUAL_L12M: {
  //       BRAND_OFFLINE: 0.405220173227065,
  //       BRAND_ONLINE: 0.131065228963644,
  //       PERFORMANCE_OFFLINE: 0.21534668238,
  //       PERFORMANCE_ONLINE: 0.190108463353021,
  //       BUSINESS: 0.0582594520762719,
  //     }
  //   },
  //   downloadAssetIdentifier: 'mmm-cz-10-2024',
  // },
  DE: {
    recommendations: [
      'Decrease consumer performance investment',
      'Increase Brand share up to 68%',
      'Potential to gain 180k incremental contract',
    ],
    data: {
      ANNUAL_BASE: {
        BRAND_OFFLINE: 0.365613828917259,
        BRAND_ONLINE: 0.142884024974491,
        PERFORMANCE_OFFLINE: 0.14600539847324,
        PERFORMANCE_ONLINE: 0.308198605906301,
        BUSINESS: 0.0372981417287088,
      },
      ANNUAL_RECO: {
        BRAND_OFFLINE: 0.523733786829846,
        BRAND_ONLINE: 0.164023040820249,
        PERFORMANCE_OFFLINE: 0.13907461192838,
        PERFORMANCE_ONLINE: 0.15451948955737,
        BUSINESS: 0.0186490708641556,
      },
      ANNUAL_L12M: {
        BRAND_OFFLINE: 0.417208420071306,
        BRAND_ONLINE: 0.0832932949736833,
        PERFORMANCE_OFFLINE: 0.113531352741935,
        PERFORMANCE_ONLINE: 0.363632692393516,
        BUSINESS: 0.0223342398195602,
      }
    },
    downloadAssetIdentifier: 'mmm-de-10-2024',
  },
  GR: {
    recommendations: [
      'Keep high Brand Share and even increase',
      'Reduce Performance online',
      'Potential to gain 25k incremental contracts',
    ],
    data: {
      ANNUAL_BASE: {
        BRAND_OFFLINE: 0.510019137442657,
        BRAND_ONLINE: 0.204442039568685,
        PERFORMANCE_OFFLINE: 0.0381054581214604,
        PERFORMANCE_ONLINE: 0.171417041682461,
        BUSINESS: 0.076016323184737,
      },
      ANNUAL_RECO: {
        BRAND_OFFLINE: 0.65882989847917,
        BRAND_ONLINE: 0.160006955069784,
        PERFORMANCE_OFFLINE: 0.0571418777863832,
        PERFORMANCE_ONLINE: 0.0860131070714901,
        BUSINESS: 0.0380081615931728,
      },
      ANNUAL_L12M: {
        BRAND_OFFLINE: 0.454506607450708,
        BRAND_ONLINE: 0.181416230476306,
        PERFORMANCE_OFFLINE: 0.087651172740914,
        PERFORMANCE_ONLINE: 0.241110744106801,
        BUSINESS: 0.0353152452252708,
      }
    },
    downloadAssetIdentifier: 'mmm-gr-10-2024',
  },
  IE: {
    recommendations: [
      'Keep high Brand Share and even increase',
      'Reduce Performance online',
      'Potential to gain 12k incremental contracts',
    ],
    data: {
      ANNUAL_BASE: {
        BRAND_OFFLINE: 0.550969576510397,
        BRAND_ONLINE: 0.163687603897337,
        PERFORMANCE_OFFLINE: 0.0620603603889102,
        PERFORMANCE_ONLINE: 0.170406143407246,
        BUSINESS: 0.05287631579611,
      },
      ANNUAL_RECO: {
        BRAND_OFFLINE: 0.539225871639188,
        BRAND_ONLINE: 0.175397949496119,
        PERFORMANCE_OFFLINE: 0.0805422890410013,
        PERFORMANCE_ONLINE: 	0.178395731926929,
        BUSINESS: 0.0264381578967629,
      },
      ANNUAL_L12M: {
        BRAND_OFFLINE: 0.619732594344578,
        BRAND_ONLINE: 0.0572370297737619,
        PERFORMANCE_OFFLINE: 0.0572370297737619,
        PERFORMANCE_ONLINE: 0.237431777098143,
        BUSINESS: 0.0390384845444771,
      }
    },
    downloadAssetIdentifier: 'mmm-ie-10-2024',
  },
  PT: {
    recommendations: [
      'Keep high Brand Share and even increase',
      'Reduce Performance online',
      'Potential to gain 35k incremental contracts',
    ],
    data: {
      ANNUAL_BASE: {
        BRAND_OFFLINE: 0.578204188522608,
        BRAND_ONLINE: 0.163444771253323,
        PERFORMANCE_OFFLINE: 0.0548949181609063,
        PERFORMANCE_ONLINE: 0.134218351510547,
        BUSINESS: 0.0692377705526159,
      },
      ANNUAL_RECO: {
        BRAND_OFFLINE: 0.539572434708335,
        BRAND_ONLINE: 0.177935013700313,
        PERFORMANCE_OFFLINE: 0.0540881893336382,
        PERFORMANCE_ONLINE: 0.181341718829924,
        BUSINESS: 0.0470626434277893,
      },
      ANNUAL_L12M: {
        BRAND_OFFLINE: 0.518314572243649,
        BRAND_ONLINE: 0.14569465056367,
        PERFORMANCE_OFFLINE: 0.0728071976900792,
        PERFORMANCE_ONLINE: 0.215536206333932,
        BUSINESS: 0.04764737316867,
      }
    },
    downloadAssetIdentifier: 'mmm-pt-10-2024',
  },
  RO: {
    recommendations: [
      'Increase Brand share, especially online',
      'Reduce Performance offline',
      'Potential to gain 22k incremental contracts',
    ],
    data: {
      ANNUAL_BASE: {
        BRAND_OFFLINE: 0.449173690844027,
        BRAND_ONLINE: 0.163243376559127,
        PERFORMANCE_OFFLINE: 0.166211459359315,
        PERFORMANCE_ONLINE: 0.166723675906808,
        BUSINESS: 0.054647797330723,
      },
      ANNUAL_RECO: {
        BRAND_OFFLINE: 0.229554800476855,
        BRAND_ONLINE: 0.273030471330917,
        PERFORMANCE_OFFLINE: 0.198591127950851,
        PERFORMANCE_ONLINE: 0.271499701576719,
        BUSINESS: 0.0273238986646583,
      },
      ANNUAL_L12M: {
        BRAND_OFFLINE: 0.337525958642157,
        BRAND_ONLINE: 0.0844464913884008,
        PERFORMANCE_OFFLINE: 0.269163054549847,
        PERFORMANCE_ONLINE: 0.204408889987946,
        BUSINESS: 0.104455605431649,
      }
    },
    downloadAssetIdentifier: 'mmm-ro-10-2024',
  },
  TR: {
    recommendations: [
      'Keep on investing in Brand',
      'Potential to gain 5k incremental contracts',
    ],
    data: {
      ANNUAL_BASE: {
        BRAND_OFFLINE: 0.366489080134344,
        BRAND_ONLINE: 0.1763316338957,
        PERFORMANCE_OFFLINE: 0.0832477468766984,
        PERFORMANCE_ONLINE: 0.285337697381441,
        BUSINESS: 0.0885938417118167,
      },
      ANNUAL_RECO: {
        BRAND_OFFLINE: 0.427309818741236,
        BRAND_ONLINE: 0.140979842409898,
        PERFORMANCE_OFFLINE: 0.0533850146774296,
        PERFORMANCE_ONLINE: 0.334028403315303,
        BUSINESS: 0.0442969208561332,
      },
      ANNUAL_L12M: {
        BRAND_OFFLINE: 0.377139039602699,
        BRAND_ONLINE: 0.177015000713697,
        PERFORMANCE_OFFLINE: 0.081356146326111,
        PERFORMANCE_ONLINE: 0.301076709138828,
        BUSINESS: 0.0634131042186652,
      }
    },
    downloadAssetIdentifier: 'mmm-tr-10-2024',
  },
  UK: {
    recommendations: [
      'Keep on investing in Brand',
      'Potential to gain 214k incremental contract',
    ],
    data: {
      ANNUAL_BASE: {
        BRAND_OFFLINE: 0.347041218845015,
        BRAND_ONLINE: 0.120287808566352,
        PERFORMANCE_OFFLINE: 0.148375734924555,
        PERFORMANCE_ONLINE: 0.317498890286226,
        BUSINESS: 0.066796347377853,
      },
      ANNUAL_RECO: {
        BRAND_OFFLINE: 0.327570749914458,
        BRAND_ONLINE: 0.145255944462431,
        PERFORMANCE_OFFLINE: 0.225157157108565,
        PERFORMANCE_ONLINE: 0.219904594414784,
        BUSINESS: 0.082111554099763,
      },
      ANNUAL_L12M: {
        BRAND_OFFLINE: 0.315227120938158,
        BRAND_ONLINE: 0.138674098917653,
        PERFORMANCE_OFFLINE: 0.17537998902587,
        PERFORMANCE_ONLINE: 0.199822084619501,
        BUSINESS: 0.170896706498818,
      }
    },
    downloadAssetIdentifier: 'mmm-uk-10-2024',
  },
}


export const mmmMarkets: VodafoneMarket[] = vodafoneMarkets.filter(
  m => !!mmmDataByIsoCode[m.iso_code]
)


export const allMarketsROIByIsoCode: Record<string, number> = {
  UK: 2.03844425831158,
  DE:	2.09162697359842,
  // CZ: 2.22801387975546,
  AL:	3.43274144473626,
  GR:	4.19104205968807,
  IE:	4.74820724728357,
  TR:	5.07363730474189,
  RO:	5.53487034889269,
  PT:	8.78997921040191,
}

export const roiMarkets: VodafoneMarket[] = vodafoneMarkets.filter(
  m => !!allMarketsROIByIsoCode[m.iso_code]
)
