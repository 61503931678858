import React, { useContext } from 'react'

import { UserPrivilegeContext } from '@percept/app-components'

import { CarouselItem } from 'components/Carousel'

import { LiveCarouselHookProps } from './typings'

import { userHasGlobalPrivileges } from '@percept/utils'
import { globalAveragePathwaysData, pathwaysDatasetColours, pathwaysSegmentOrder } from 'components/Pathways/graphData'
import { ChartData, percentageFormatter } from '@percept/mui/charts'
import { capitalize } from 'lodash-es'
import { Box, Gauge, Typography } from '@percept/mui'


export const usePathwaysCarouselItem = (props: LiveCarouselHookProps): CarouselItem | null => {
  const privileges = useContext(UserPrivilegeContext)

  const hasRequiredPrivileges = userHasGlobalPrivileges(
    ['vfMicrosites.pathways.view'],
    privileges.global_privileges,
  )

  const chartData: ChartData = pathwaysSegmentOrder.map( segment => ({
    value: globalAveragePathwaysData[segment],
    label: capitalize(segment),
    color: pathwaysDatasetColours[segment],
  }))

  if( !hasRequiredPrivileges ){
    return null
  }


  return {
    title: 'Placing the Customer at the Center of Growth',
    description: 'Utilize these insights to gain a deeper understanding of our customers',
    href: '/wizard/strategy/pathways',
    infoGraphicContent: (
      <Box
        width='100%'
        height='calc(100% - 100px)'
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'>
        <Gauge
          orientation='horizontal'
          data={chartData}
          width='100%'
          height='4rem'
          showValues
          valueFormatter={(v): React.ReactNode => (
            <Box
              width='100%'
              height='100%'
              fontWeight={700}
              fontSize={14}
              display='flex'
              justifyContent='center'
              alignItems='center'>
              { percentageFormatter(v) }
            </Box>
          )} />
        <Box mt={2}>
          <Typography variant='h5'>
            Global Average
          </Typography>
        </Box>
      </Box>
    ),
    ...props,
  }
}
