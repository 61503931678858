
export const produceKeyedMapping = <T extends object>(
  items: T[] | null | undefined,
  key: Extract<keyof T, string>
): Record<string, T> => {
  if( !items ){
    return {}
  }
  return items.reduce( (acc, item) => {
    acc[item[key] as string] = item
    return acc
  }, {} as Record<string, T>)
}

export const filterTruthyMapping = <T extends object>(obj: T): Partial<T> => {
  return Object.entries(obj).reduce( (acc, [k, v]) => {
    if( v ){
      acc[k as keyof T] = v
    }
    return acc
  }, {} as Partial<T>)
}
