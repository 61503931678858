import React from 'react'

import { AppTheme, Box, DialogProps, IconButton, Loader, PerceptAppBar, Typography } from '@percept/mui'

import { DefaultBackgroundDialog } from './DefaultBackgroundDialog'

import { ExamplesDataTable } from '../ReportDashboardApp'

import { ArrowBack } from '@percept/mui/icons'

import { DimensionType, MetricExample, MetricMetadataType } from '@percept/types'
import { DSLRenderer } from './DSLComponents'
import { useInsightOutputUnitResultExamples } from '@percept/hooks'


export type ExamplesDialogProps = {
  appTheme: AppTheme
  title: string | null | undefined
  text: string | null | undefined
  series_id: string
  insightOutputUnitId?: string | null
  total_entities?: number
  examples: MetricExample[]
  metricMetadata: MetricMetadataType
  DialogProps: Partial<Omit<DialogProps, 'onClose'>> & {
    onClose: () => void
  }
  currency?: string
  dimension?: DimensionType
}

export const ExamplesDialog = ({
  appTheme,
  title,
  text,
  examples,
  series_id,
  insightOutputUnitId,
  total_entities,
  metricMetadata,
  DialogProps,
  currency,
  dimension = 'cost',
}: ExamplesDialogProps): JSX.Element => {
  const isNewInsightsReportPayload = !!(
    insightOutputUnitId && total_entities !== undefined && !examples.length
  )
  const examplesQuery = useInsightOutputUnitResultExamples({
    series_id,
    insight_output_unit_result_id: insightOutputUnitId || '',
    enabled: isNewInsightsReportPayload && !!total_entities,
  })

  const insightExamples = (
    isNewInsightsReportPayload ?
      examplesQuery.data : examples
  )
  return (
    <DefaultBackgroundDialog
      open
      fullScreen
      maxWidth={false}
      {...DialogProps}>
      <PerceptAppBar
        color='secondary'
        startIcon={
          <IconButton
            color='inherit'
            onClick={DialogProps.onClose}>
            <ArrowBack />
          </IconButton>
        }>
        <Box ml={2}>
          <Typography variant='h5'>
            { title }
          </Typography>
        </Box>
      </PerceptAppBar>
      <Box pt={12} px={3}>
        <Typography variant='h5'>
          <DSLRenderer
            appTheme={appTheme}
            text={text} />
        </Typography>
        <Box mt={5}>
          { isNewInsightsReportPayload && examplesQuery.isLoading && (
            <Loader preset='fullsize' minHeight='12rem' />
          )}
          { insightExamples && (
            <ExamplesDataTable
              examples={insightExamples}
              dimension={dimension}
              currency={currency}
              metadata={metricMetadata} />
          )}
        </Box>
      </Box>
    </DefaultBackgroundDialog>
  )
}
