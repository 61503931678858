import React from 'react'
import { Checkbox, FormControl, FormControlLabel, FormControlProps, FormGroup, FormGroupProps, FormLabel } from '@material-ui/core'
import { MenuOption } from '../Menus'
import { every, isEqual, some } from 'lodash-es'


export type CheckboxGroupProps<T> = {
  name: React.ReactNode
  value: T[]
  onChange: (value: T[]) => void
  options: MenuOption<T>[]
  areValuesEqual?: (a: T, b: T) => boolean
  FormGroupProps?: FormGroupProps
} & Partial<Pick<FormControlProps, 'color' | 'disabled' | 'size'>>

export function CheckboxGroup<T>({
  name,
  value,
  options,
  onChange,
  areValuesEqual = isEqual,
  FormGroupProps = {},
  ...props
}: CheckboxGroupProps<T>): JSX.Element {
  const allValuesEqual = every(options, o => some(value, v => areValuesEqual(o.value, v)))
  return (
    <FormControl color='secondary' component='fieldset' {...props}>
      <FormLabel>{name}</FormLabel>
      <FormGroup {...FormGroupProps}>
        <FormControlLabel
          name='select_all'
          label={
            allValuesEqual ?
              'Deselect All' :
              'Select All'
          }
          control={
            <Checkbox
              indeterminate
              checked={allValuesEqual}
              onChange={(): void => {
                onChange(
                  allValuesEqual ?
                    [] :
                    options.map( o => o.value )
                )
              }} />
          } />
        { options.map( (option, i) => {
          const isSelected = some(value, v => areValuesEqual(v, option.value))
          return (
            <FormControlLabel
              key={i}
              label={option.label}
              control={
                <Checkbox
                  checked={isSelected}
                  onChange={(): void => {
                    if( isSelected ){
                      onChange(value.filter( v => !areValuesEqual(v, option.value)))
                    }else{
                      onChange([...value, option.value])
                    }
                  }} />
              } />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}
