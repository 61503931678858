import React, { useEffect, useState } from 'react'

import {
  BackdropLoader,
  Box,
  Card,
  DateRangePopover,
  dateRangeCalculators,
  DateRangeValue,
  LinearProgress,
  Typography,
  DateRangePresetOption,
  DateRangePreset,
  makeAppStyles,
} from '@percept/mui'

import { MediaQualityRateTable } from './MediaQualityRateTable'

import { useMediaQualityRate } from './hook'

import { usePlatformUnitProviderInfo } from '@percept/hooks'

import { getMaxDate } from './lib'

import { useActivePlatformUnit } from 'components/Organisation'

import { some } from 'lodash-es'


const useStyles = makeAppStyles( theme => ({
  card: {
    position: 'relative',
  },
  refetchIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 4,
    zIndex: theme.zIndex.appBar + 1,
  },
}))


export const MediaQualityRate = (): JSX.Element => {

  // const [platformUnits] = useUserPlatformUnits()

  // const platformUnitOptions = flattenNestedItems(
  //   platformUnits.data || []
  // ).filter( u => !isPlatformUnitContainer(u) )

  // const [platformUnit, setPlatformUnit] = useState<PlatformUnit | null>(platformUnitOptions[0] || null)

  const activePlatformUnit = useActivePlatformUnit()

  const org_unit_id = activePlatformUnit && activePlatformUnit.id

  const [referenceDates] = usePlatformUnitProviderInfo({ org_unit_id })

  const maxDate = referenceDates.data && getMaxDate(referenceDates.data)

  const maxDateRef = maxDate && maxDate.getTime()

  const [{ dateRange, dateRangePreset }, setDateRangeConfig] = useState<{
    dateRange: [Date | null, Date | null]
    dateRangePreset: DateRangePresetOption
  }>(() => {
    const defaultDateRangePreset: DateRangePreset = 'current-financial-year'
    return {
      dateRange: (
        maxDate === null ?
          [null, null] :
          dateRangeCalculators[defaultDateRangePreset](maxDate)
      ),
      dateRangePreset: defaultDateRangePreset,
    }
  })

  const [expandedViewEnabled, setExpandedViewEnabled] = useState(false)

  useEffect(() => {
    if( maxDateRef && dateRange[0] === null && dateRangePreset !== 'custom' ){
      setDateRangeConfig( prev => ({
        ...prev,
        dateRange: dateRangeCalculators[dateRangePreset](new Date(maxDateRef))
      }))
    }
  }, [maxDateRef, dateRangePreset, dateRange])

  // useEffect(() => {
  //   if( platformUnit === null && platformUnits.data && platformUnits.data.length ){
  //     const filteredPlatformUnits = flattenNestedItems(
  //       platformUnits.data || []
  //     ).filter( u => !isPlatformUnitContainer(u) )
  //     const defaultOption = filteredPlatformUnits[0]
  //     setPlatformUnit(defaultOption)
  //   }
  // }, [platformUnit, platformUnits.data])

  const queryResult = useMediaQualityRate({
    platformUnitId: org_unit_id,
    start: dateRange && dateRange[0] || null,
    end: dateRange && dateRange[1] || null,
    groupBy: 'PROVIDER',
  })

  const queryResultTotal = useMediaQualityRate({
    platformUnitId: org_unit_id,
    start: dateRange && dateRange[0] || null,
    end: dateRange && dateRange[1] || null,
  })

  const queryResultByPlacement = useMediaQualityRate({
    platformUnitId: org_unit_id,
    start: dateRange && dateRange[0] || null,
    end: dateRange && dateRange[1] || null,
    groupBy: 'PLACEMENT',
    enabled: expandedViewEnabled,
  })

  const classes = useStyles()

  const showRefetching = some(
    [queryResult, queryResultTotal, queryResultByPlacement],
    q => q.isRefetching
  )

  const showLoader = some(
    [queryResult, queryResultTotal],
    q => (
      q.isLoading
      || (!q.data && !q.isError)
    )
  ) || (expandedViewEnabled && queryResultByPlacement.isLoading)

  return (
    <Box pt={1}>

      <Box p={3}>
        <Box mb={3} display='flex' alignItems='center'>
          <Typography variant='h4'>Media Quality Rate</Typography>
          {/* <Box mx={3}>
            <RoundedPlainTextButtonMenu
              TriggerProps={{
                variant: 'contained',
                color: 'secondary',
                size: 'small',
                endIcon: <ArrowDropDown />,
              }}
              value={platformUnit}
              label={platformUnit && platformUnit.name}
              isEqual={areOrgsEqual}
              options={
                platformUnitOptions.map( u => ({
                  label: u.name,
                  value: u,
                }))
              }
              onChange={(e, value) => {
                setPlatformUnit(value)
              }} />
          </Box> */}

          { dateRange[0] && dateRange[1] && (
            <Box ml={3}>
              <DateRangePopover
                color='primary'
                dateRangePreset={dateRangePreset}
                value={dateRange as DateRangeValue}
                onChange={(dateRange, dateRangePreset) => {
                  setDateRangeConfig({
                    dateRange,
                    dateRangePreset
                  })
                }}
                maxDate={maxDate} />
            </Box>
          )}
        </Box>

        <Card className={classes.card}>
          { showRefetching && (
            <LinearProgress variant='indeterminate' className={classes.refetchIndicator} />
          )}
          <MediaQualityRateTable
            rowsByProvider={queryResult.data}
            rowsByPlacement={queryResultByPlacement.data}
            totalRows={queryResultTotal.data}
            onRowExpand={(): void => {
              setExpandedViewEnabled(true)
            }} />
        </Card>
      </Box>

      { showLoader && <BackdropLoader /> }
    </Box>
  )
}
