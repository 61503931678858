import React, { useMemo, useReducer } from 'react'

import {
  Box,
  Card,
  dateRangeCalculators,
  DateRangePopover,
  DateRangePresetOption,
  Loader,
  RoundedPlainTextButtonMenu,
  SimpleTable,
  Typography,
  makeAppStyles,
  LinearProgress,
  useAppTheme,
  Grid,
  RoundedPlainTextButton,
  CircularProgress,
  DateRangePreset,
  MenuOption,
} from '@percept/mui'

import { ArrowDropDown, CloudDownload, Help, Language } from '@percept/mui/icons'

import { capitalize, get, some } from 'lodash-es'

import { PrimaryApplication, SecondaryApplication, useTenantMarketUserEvents, useTenantMarketUserEventsCSVExport } from '@percept/app-components'

import { dmyFormatter, LegendItem, MultiLineProps, numberFormatter, ResponsiveMultiLine } from '@percept/mui/charts'

import { VODAFONE_GLOBAL_ID, VodafoneMarket, vodafoneMarkets } from 'vodafoneMarkets'

import {
  analyticsTableColumns,
  AnalyticsTableRow,
  filteredPrimaryApplications,
  filteredSecondaryApplications,
  getAnalyticsTimeseriesDatasets,
  getCombinedApplicationTimeseriesDataset,
  getMultiDatasetDefs,
  getPrimaryTableRows,
  getSecondaryTableRowMapping,
  globalMarketObject,
  unknownMarketObject,
  ViewType,
} from './lib'

import { analyticsReducer, getAvailableGranularities, getMaximumGranularity, ViewSegmentation } from './reducer'
import { TimeseriesGranularity } from '@percept/types'
import { MultiMarketSelect } from 'components/Selects'
import { MultiApplicationSelect } from './MultiApplicationSelect'
import { deslugify } from '@percept/utils'


const useStyles = makeAppStyles( theme => ({
  title: {
    marginRight: theme.spacing(3),
  },
  selector: {
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  inlineSpacedFragment: {
    marginRight: theme.spacing(1),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  chartCard: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    position: 'relative',
  },
  chartContainer: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  tableContainer: {
    position: 'relative',
  },
  refetchIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 4,
    zIndex: theme.zIndex.appBar,
  },
}))


const CHART_PROPS: Partial<MultiLineProps> = {
  height: 300,
  xScaleType: 'time',
  roundXDomain: false,
  grid: 'rows',
  axisText: true,
  yTickFormatter: numberFormatter,
  xTickFormatter: dmyFormatter,
  tooltipStyleOverrides: {
    zIndex: 9999,
  }
}

const maxDate = new Date()

const analyticsDateRangePresetOptions: DateRangePresetOption[] = [
  'today',
  'yesterday',
  'last-7-days',
  'last-30-days',
  'last-week',
  'last-month',
  'last-quarter',
  'current-year',
  'last-year',
]

const defaultPresetGranularities: Partial<Record<DateRangePreset, TimeseriesGranularity>> = {
  'current-financial-year': 'MONTH',
  'current-year': 'MONTH',
  'last-financial-year': 'MONTH',
  'last-year': 'MONTH',
  'last-quarter': 'MONTH',
  'last-month': 'WEEK',
  'last-30-days': 'WEEK',
  'last-7-days': 'DAY',
  'last-week': 'DAY',
}

const defaultDateRangePreset: DateRangePresetOption = 'last-7-days'

const granularityLabels: Record<TimeseriesGranularity, string> = {
  DAY: 'Daily',
  WEEK: 'Weekly',
  MONTH: 'Monthly',
  QUARTER: 'Quarterly',
  CALENDAR_YEAR: 'By Calendar Year',
  FINANCIAL_YEAR: 'By Financial Year',
}

export const marketOptions: (
  VodafoneMarket & {
    labelOverride?: JSX.Element | null
  }
)[] = [
  {
    ...globalMarketObject,
    labelOverride: (
      <Box display='flex' alignItems='center'>
        <Language style={{fontSize: 18, marginRight: 4, marginLeft: -2}} />
        Global
      </Box>
    ),
  },
  ...vodafoneMarkets,
  {
    ...unknownMarketObject,
    labelOverride: (
      <Box display='flex' alignItems='center'>
        <Help style={{fontSize: 18, marginRight: 4, marginLeft: -2}} />
        Unknown
      </Box>
    ),
  },
]

const getSelectedMarketsContent = (
  marketSlugs: string[]
): JSX.Element => {
  if( marketSlugs.length === marketOptions.length ){
    return <>All Markets</>
  }
  const names: string[] = []
  for( const market of marketOptions ){
    if( !marketSlugs.includes(market.slug) ){
      continue
    }
    names.push(market.name)
  }
  return <>{names.join(', ')}</>
}

const getSelectedApplicationsContent = (
  primaryApplications: PrimaryApplication[],
  secondaryApplications: SecondaryApplication[],
): JSX.Element => {
  if(
    primaryApplications.length === filteredPrimaryApplications.length
    && secondaryApplications.length === filteredSecondaryApplications.length
  ){
    return <>All Applications</>
  }
  const names: string[] = [
    ...primaryApplications.map(deslugify),
    ...secondaryApplications.map(deslugify),
  ]
  return <>{names.join(', ')}</>
}


export const Analytics = (): JSX.Element => {
 
  const initialDateRange = dateRangeCalculators[defaultDateRangePreset](maxDate)

  const [state, dispatch] = useReducer(analyticsReducer, {
    markets: marketOptions.map( m => m.slug ),
    primaryApplications: filteredPrimaryApplications,
    secondaryApplications: filteredSecondaryApplications,
    viewType: 'USERS',
    viewSegmentation: 'MARKET',
    dateRange: initialDateRange,
    dateRangePreset: defaultDateRangePreset,
    granularity: getMaximumGranularity(initialDateRange) || 'FINANCIAL_YEAR',
    availableGranularities: getAvailableGranularities(initialDateRange),
  })

  const {
    granularity,
    dateRange,
    dateRangePreset,
    viewType,
    viewSegmentation,
    markets,
    primaryApplications,
    secondaryApplications,
  } = state

  const baseHookParams: {
    start: Date
    end: Date
    market_slugs: string[] | null
  } = {
    start: dateRange[0],
    end: dateRange[1],
    market_slugs: markets,
  }

  const primaryQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['MARKET', 'PRIMARY_APPLICATION'],
    primary_applications: primaryApplications,
    secondary_applications: secondaryApplications,
  })

  const secondaryQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['MARKET', 'SECONDARY_APPLICATION'],
    secondary_applications: secondaryApplications,
  })

  const marketTimeseriesQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['DATE', 'MARKET'],
    granularity,
    primary_applications: primaryApplications,
    secondary_applications: secondaryApplications,
  })

  const primaryApplicationTimeseriesQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['DATE', 'PRIMARY_APPLICATION'],
    granularity,
    primary_applications: primaryApplications,
  })

  const secondaryApplicationTimeseriesQueryResult = useTenantMarketUserEvents({
    tenant_id: VODAFONE_GLOBAL_ID,
    ...baseHookParams,
    segments: ['DATE', 'SECONDARY_APPLICATION'],
    granularity,
    secondary_applications: secondaryApplications,
  })

  const exportHook = useTenantMarketUserEventsCSVExport()

  /* Table data */

  const primaryRows = useMemo(() => {
    if( !primaryQueryResult.data ){
      return []
    }
    return getPrimaryTableRows(primaryQueryResult.data, viewType)
  }, [primaryQueryResult.data, viewType])

  const secondaryRowMapping = useMemo(() => {
    if( !secondaryQueryResult.data ){
      return {}
    }
    return getSecondaryTableRowMapping(secondaryQueryResult.data, viewType)
  }, [secondaryQueryResult.data, viewType])

  /* Timeseries data */

  const appTheme = useAppTheme()

  const timeseries = useMemo(() => {
    if(
      !marketTimeseriesQueryResult.data
      || !primaryApplicationTimeseriesQueryResult.data
      || !secondaryApplicationTimeseriesQueryResult.data
    ){
      return null
    }
    return {
      market: getAnalyticsTimeseriesDatasets({
        userEvents: marketTimeseriesQueryResult.data,
        dateRange,
        granularity,
        viewType,
        appTheme,
        groupBy: 'MARKET',
      }),
      combinedApplication: getCombinedApplicationTimeseriesDataset({
        primary: primaryApplicationTimeseriesQueryResult.data,
        secondary: secondaryApplicationTimeseriesQueryResult.data,
        primaryApplicationFilter: primaryApplications,
        secondaryApplicationFilter: secondaryApplications,
        dateRange,
        granularity,
        viewType,
        appTheme,
      }),
    }
  }, [
    marketTimeseriesQueryResult.data,
    primaryApplicationTimeseriesQueryResult.data,
    secondaryApplicationTimeseriesQueryResult.data,
    dateRange,
    granularity,
    viewType,
    appTheme,
    primaryApplications,
    secondaryApplications,
  ])

  const timeseriesHooks = [
    marketTimeseriesQueryResult,
    primaryApplicationTimeseriesQueryResult,
    secondaryApplicationTimeseriesQueryResult,
  ]

  const isTimeseriesLoading = some(timeseriesHooks, q => q.isLoading)

  const isTimeseriesError = some(timeseriesHooks, q => q.isError)

  const getRowGroup = (row: AnalyticsTableRow): AnalyticsTableRow[] => (
    secondaryRowMapping[row.label] || []
  )

  const viewTypeLabel = capitalize(viewType)

  const appTimeseriesDataSignal = get(timeseries, ['combinedApplication', 0, 'data', 'length'], 0)
  const marketTimeseriesDataSignal = get(timeseries, ['market', 0, 'data', 'length'], 0)

  const timeseriesDataSignals = [
    appTimeseriesDataSignal,
    marketTimeseriesDataSignal,
  ]

  const hasAnyTimeseriesData = some(timeseriesDataSignals)

  const numTicks = Number(
    timeseries && Math.min(
      Math.max(...timeseriesDataSignals),
      10
    )
  )

  const chartIsRefetching = (
    viewSegmentation === 'MARKET' ?
      marketTimeseriesQueryResult.isRefetching :
      primaryApplicationTimeseriesQueryResult.isRefetching
  )

  const chartDatasets = (
    viewSegmentation === 'MARKET' ?
      timeseries && timeseries.market :
      timeseries && timeseries.combinedApplication
  )

  const chartDatasetSignal = get(chartDatasets, [0, 'data', 'length'], 0)

  const classes = useStyles()

  return (
    <Box p={3}>
      <Box display='flex' alignItems='center' mb={2}>
        <Typography variant='h2' className={classes.title}>
          Analytics
        </Typography>

        <DateRangePopover
          ButtonProps={{
            className: classes.selector,
            color: 'default',
          }}
          color='primary'
          dateRangePreset={dateRangePreset}
          dateRangePresetOptions={analyticsDateRangePresetOptions}
          value={state.dateRange}
          onChange={(dateRange, dateRangePreset): void => {
            let granularity: TimeseriesGranularity | undefined
            if( dateRangePreset !== 'custom' ){
              granularity = defaultPresetGranularities[dateRangePreset]
            }
            dispatch({
              type: 'SET_DATE_RANGE',
              payload: {
                dateRange,
                dateRangePreset,
                granularity,
              }
            })
          }}
          maxDate={maxDate} />

        <RoundedPlainTextButtonMenu
          TriggerProps={{
            className: classes.selector,
            variant: 'contained',
            color: 'default',
            size: 'small',
            endIcon: <ArrowDropDown />,
          }}
          value={granularity}
          label={granularityLabels[granularity]}
          options={
            state.availableGranularities.map( value => ({
              value,
              label: granularityLabels[value],
            }))
          }
          onChange={(e, payload): void => {
            dispatch({ type: 'SET_GRANULARITY', payload })
          }} />

        <MultiMarketSelect
          ButtonPopoverProps={{
            className: classes.selector,
            variant: 'contained',
            color: 'default',
            size: 'small',
            endIcon: <ArrowDropDown />,
          }}
          value={markets}
          valueKey='slug'
          options={marketOptions}
          onChange={(payload): void => {
            dispatch({ type: 'SET_MARKETS', payload })
          }} />

        <MultiApplicationSelect
          ButtonPopoverProps={{
            className: classes.selector,
            variant: 'contained',
            color: 'default',
            size: 'small',
            endIcon: <ArrowDropDown />,
          }}
          primaryApplications={primaryApplications}
          secondaryApplications={secondaryApplications}
          primaryApplicationOptions={filteredPrimaryApplications}
          secondaryApplicationOptions={filteredSecondaryApplications}
          onChange={(payload): void => {
            dispatch({ type: 'SET_APPLICATION_FILTERS', payload })
          }} />

        <RoundedPlainTextButtonMenu
          TriggerProps={{
            className: classes.selector,
            variant: 'contained',
            color: 'default',
            size: 'small',
            endIcon: <ArrowDropDown />,
          }}
          value={viewType}
          label={capitalize(viewType)}
          options={[
            { value: 'USERS', label: 'Users' },
            { value: 'VIEWS', label: 'Views' },
          ] as MenuOption<ViewType>[]}
          onChange={(e, value): void => {
            dispatch({ type: 'SET_VIEW_TYPE', payload: value })
          }} />
      </Box>

      <Box display='flex' alignItems='center' fontSize={14} mb={0.5}>
        <strong style={{marginRight: 12}}>Selected Markets</strong>
        { getSelectedMarketsContent(markets) }
      </Box>

      <Box display='flex' alignItems='center' fontSize={14}>
        <strong style={{marginRight: 12}}>Selected Applications</strong>{' '}
        { getSelectedApplicationsContent(primaryApplications, secondaryApplications) }
      </Box>

      { isTimeseriesError ? (
        <Box my={10} display='flex' justifyContent='center'>
          <Typography variant='h3' color='textSecondary'>
            An error occurred and timeseries data could not be loaded
          </Typography>
        </Box>
      ) : isTimeseriesLoading ? (
        <Loader preset='fullsize' minHeight={CHART_PROPS.height} />
      ) : timeseries && hasAnyTimeseriesData && chartDatasets && (
        <Box mt={3} mb={5}>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.gridItem}>
              <Card className={classes.chartCard}>
                { chartIsRefetching && (
                  <LinearProgress className={classes.refetchIndicator} />
                ) }
                <Typography variant='h5'>
                  <span className={classes.inlineSpacedFragment}>{viewTypeLabel} over time by</span>
                  <RoundedPlainTextButtonMenu
                    TriggerProps={{
                      variant: 'contained',
                      size: 'small',
                      endIcon: <ArrowDropDown />,
                    }}
                    value={viewSegmentation}
                    label={capitalize(viewSegmentation)}
                    options={[
                      {value: 'MARKET', label: 'Market'},
                      {value: 'APPLICATION', label: 'Application'},
                    ] as MenuOption<ViewSegmentation>[]}
                    onChange={(e, value): void => {
                      dispatch({ type: 'SET_VIEW_SEGMENTATION', payload: value })
                    }} />
                </Typography>
                <div className={classes.chartContainer}>
                  <ResponsiveMultiLine
                    {...CHART_PROPS}
                    defs={getMultiDatasetDefs(chartDatasets)}
                    numXTicks={chartDatasetSignal ? numTicks : 0}
                    datasets={chartDatasets} />
                </div>

                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  { chartDatasets.map( d => (
                    <LegendItem
                      key={d.key}
                      datum={d} />
                  ))}
                </Box>
              </Card>
            </Grid>
            {/* <Grid item xs={6} className={classes.gridItem}>
              <Card className={classes.chartCard}>
                { (marketTimeseriesQueryResult.isRefetching) && (
                  <LinearProgress className={classes.refetchIndicator} />
                ) }
                <Typography variant='h5'>
                  {viewTypeLabel} over time by market
                </Typography>
                <div className={classes.chartContainer}>
                  <ResponsiveMultiLine
                    {...CHART_PROPS}
                    defs={getMultiDatasetDefs(timeseries.market)}
                    numXTicks={marketTimeseriesDataSignal ? numTicks : 0}
                    datasets={timeseries.market} />
                </div>

                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  { timeseries.market.map( d => (
                    <LegendItem
                      key={d.key}
                      datum={d} />
                  ))}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <Card className={classes.chartCard}>
                { (primaryApplicationTimeseriesQueryResult.isRefetching) && (
                  <LinearProgress className={classes.refetchIndicator} />
                ) }
                <Typography variant='h5'>
                  {viewTypeLabel} over time by section
                </Typography>
                <div className={classes.chartContainer}>
                  <ResponsiveMultiLine
                    {...CHART_PROPS}
                    defs={getMultiDatasetDefs(timeseries.combinedApplication)}
                    numXTicks={appTimeseriesDataSignal ? numTicks : 0}
                    datasets={timeseries.combinedApplication} />
                </div>

                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  { timeseries.combinedApplication.map( d => (
                    <LegendItem
                      key={d.key}
                      datum={d} />
                  ))}
                </Box>
              </Card>
            </Grid> */}
          </Grid>
        </Box>
      )}
      
      { (primaryQueryResult.error || secondaryQueryResult.error) ? (
        <Box my={10} display='flex' justifyContent='center'>
          <Typography variant='h3' color='textSecondary'>
            An error occurred and the data could not be loaded
          </Typography>
        </Box>
      ) : !(primaryQueryResult.data && secondaryQueryResult.data) ? (
        <Loader preset='fullsize' minHeight='15rem' />
      ) : !primaryRows.length ? (
        <Box my={10} display='flex' justifyContent='center'>
          <Typography variant='h3' color='textSecondary'>
            No results for selected time period
          </Typography>
        </Box>
      ) : (
        <>
          <Box display='flex' justifyContent='flex-end' mb={3}>
            <RoundedPlainTextButton
              variant='contained'
              color='secondary'
              size='small'
              disabled={exportHook.isLoading}
              startIcon={
                exportHook.isLoading ?
                  <CircularProgress size='1em' color='inherit' /> :
                  <CloudDownload />
              }
              onClick={(): void => {
                exportHook.mutate({
                  tenant_id: VODAFONE_GLOBAL_ID,
                  ...baseHookParams,
                  primary_applications: primaryApplications,
                  secondary_applications: secondaryApplications,
                  cardinality_type: viewType,
                })
              }}>
              Download CSV
            </RoundedPlainTextButton>
          </Box>

          <Card className={classes.tableContainer}>
            <SimpleTable
              size='small'
              sortable
              pinFirstColumn
              stickyHeader
              unsetStickyHeaderZIndex={false}
              wrapCellText={false}
              grouped={true}
              getRowGroup={getRowGroup}
              columns={analyticsTableColumns}
              rows={primaryRows} />
            { (primaryQueryResult.isRefetching || secondaryQueryResult.isRefetching) && (
              <LinearProgress className={classes.refetchIndicator} />
            ) }
          </Card>
        </>
      )}
    </Box>
  )
}
