
import createMuiTheme, { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme'
import createPalette, { Palette, PaletteColor, PaletteColorOptions, PaletteOptions } from '@material-ui/core/styles/createPalette'

import { ScaleLinear, scaleLinear, scaleOrdinal, ScaleOrdinal } from 'd3-scale'

import { mapValues } from 'lodash-es'

import { ChannelKey } from '@percept/types'

import { LogoComponentProps } from '../components/Logos/typings'
import { CreateCSSProperties, CSSProperties } from '@material-ui/core/styles/withStyles'


export type MetricPalette = {
  red: string
  amber: string
  green: string
  backgroundColor: string
  color: string
  axis: {
    line: string
    text: string
  }
}


export interface AppPaletteOptions extends PaletteOptions {
  neutral?: PaletteColorOptions
  channel: Record<ChannelKey, PaletteColorOptions>
}

export interface AppPalette extends Palette {
  neutral: PaletteColor
  channel: Record<ChannelKey, PaletteColor>
}

export type MetricThemeOptions = {
  palette: MetricPalette
}


export type ChartThemeStyleOptions = {
  grid: {
    stroke: string
  }
  axis: {
    stroke: string
  }
  tick: {
    stroke: string
  }
  tooltip: {
    valueColor: string
    valueBackgroundColor: string
    secondaryContentColor?: string
  }
}

export type ChartThemeOptions = {
  healthColourStops: [string, string, string]
  informationalColourStops: [string, string]
  genericColourStops?: string[]
} & ChartThemeStyleOptions

export type ChartThemeFunctionality = {
  healthColourScale: ScaleLinear<number, string>
  generateHealthColourScale: (domain: [number, number]) => ScaleLinear<number, string>
  getInformationalColourScale: (domain: [number, number]) => ScaleLinear<number, string>
  getOrdinalColourScale: <T extends string = string>(domain: T[]) => ScaleOrdinal<T, string>
} & ChartThemeStyleOptions


export type AppBranding = {
  label: string
  LogoComponent: (props: LogoComponentProps) => JSX.Element
}


export type AppThemeComponentStyle = (
  {
    color: string
    backgroundColor: string
  } & CSSProperties
)


export type AppThemeComponents = {
  appBarTabIndicator?: {
    colorPrimary: Omit<AppThemeComponentStyle, 'color'>
    colorSecondary: Omit<AppThemeComponentStyle, 'color'>
  }
  authenticator: {
    root: CSSProperties
    heading: CSSProperties
    input: AppThemeComponentStyle
  }
  contrastPanel: {
    root: AppThemeComponentStyle
  }
  performanceTable: {
    cellCompact: CreateCSSProperties
  }
  reportDashboard?: {
    secondaryHealth: {
      backgroundColor: string
    }
  }
  table: {
    footer: AppThemeComponentStyle & {
      borderColor: string
    }
  }
}


export type AppTheme = Theme & {
  chart: ChartThemeFunctionality
  palette: AppPalette
  branding: AppBranding
  appComponents: AppThemeComponents
}


export type AppThemeOptions = ThemeOptions & {
  chart: ChartThemeOptions
  palette: AppPaletteOptions
  branding: AppBranding
  appComponents: AppThemeComponents
}


export const createAppTheme = ({
  chart,
  palette,
  branding,
  appComponents,
  ...options
}: AppThemeOptions, ...args: object[]): AppTheme => {
  const muiTheme = createMuiTheme(options, ...args)

  const muiPalette = createPalette({
    ...palette,
    neutral: palette.neutral || palette.primary,
  })

  const appPalette: AppPalette = {
    ...muiPalette,
    neutral: muiPalette.augmentColor(muiPalette.neutral),
    channel: mapValues(palette.channel, muiPalette.augmentColor),
  }

  const genericColourStops = (
    chart.genericColourStops || [
      appPalette.channel.programmatic.main,
      appPalette.channel.search.main,
      appPalette.channel.social.main,
      appPalette.channel.programmatic.dark,
      appPalette.channel.search.dark,
      appPalette.channel.social.dark,
      appPalette.channel.programmatic.light,
      appPalette.channel.search.light,
      appPalette.channel.social.light,
    ]
  )

  const chartFunctionality: ChartThemeFunctionality = {
    healthColourScale: (
      scaleLinear<number, string>()
        .domain([0, 0.5, 1])
        .range(chart.healthColourStops as unknown as number[])
    ),
    generateHealthColourScale: domain => (
      scaleLinear<number, string>()
        .domain([domain[0], (domain[0] + domain[1] / 2), domain[1]])
        .range(chart.healthColourStops as unknown as number[])
    ),
    getInformationalColourScale: domain => (
      scaleLinear<number, string>()
        .domain(domain)
        .range(chart.informationalColourStops as unknown as number[])
    ),
    getOrdinalColourScale: <T extends string = string>(domain: T[]) => (
      scaleOrdinal<T, string>()
        .range(genericColourStops)
        .domain(domain)
    ),
    ...chart,
  }

  const appTheme: AppTheme = {
    ...muiTheme,
    branding,
    appComponents,
    palette: appPalette,
    chart: chartFunctionality,
  }
  return appTheme
}
