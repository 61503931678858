import React from 'react'

import { AppTheme, Box, Column } from '@percept/mui'

import { Help, Language } from '@percept/mui/icons'

import {
  AggregatedMarketUserEvent,
  getPrimaryApplicationLabel,
  getSecondaryApplicationLabel,
  PrimaryApplication,
  SecondaryApplication,
} from '@percept/app-components'

import { MarketDisplayLabel } from 'components/MarketDisplay'

import { get, intersection, mapValues, sortBy, uniq } from 'lodash-es'

import { VODAFONE_GLOBAL_ID, VodafoneMarket, vodafoneMarkets } from 'vodafoneMarkets'

import { MultiDataset, SVGDatumType } from '@percept/mui/charts'

import { produceKeyedMapping } from '@percept/utils'
import { ScaleOrdinal } from 'd3'
import { addDays, addMonths, addQuarters, addWeeks, addYears, format, startOfMonth, startOfWeek } from 'date-fns'
import { TimeseriesGranularity } from '@percept/types'


export type ViewType = 'USERS' | 'VIEWS'

export const VODAFONE_GLOBAL_SLUG = 'vodafone-group'
export const UNKNOWN_MARKET_SLUG = 'unknown'

const primaryApplicationOrder: PrimaryApplication[] = [
  'VODAFONE_MEDIA_WIZARD', 'MEDIA_INVESTMENT', 'MEDIA_QUALITY_DASHBOARD'
]

export const filteredPrimaryApplications: PrimaryApplication[] = [
  'MEDIA_QUALITY_DASHBOARD',
  'MEDIA_INVESTMENT',
]

export const filteredSecondaryApplications: SecondaryApplication[] = [
  'WIZARD_HOME',
  'WIZARD_OVERVIEW',
  'BRAND_GROWTH_PLANNER',
  'MEDIA_MIX_MODELLING',
  'NEWS',
  'OPTIMISATION',
  'PARTNERSHIPS',
  'PATHWAYS',
  'REPORTING',
  'SMART_CAMPAIGN_ASSESSMENT',
  'SPONSORSHIPS',
  'STRATEGY',
  'WASTAGE_TREND_REPORTS',
]


export const globalMarketObject: VodafoneMarket = {
  slug: VODAFONE_GLOBAL_SLUG,
  id: VODAFONE_GLOBAL_ID,
  name: 'Global',
  creativeXMarket: '',
  iso_code: '',
  shareOfSpendTarget: null,
  ordinal_no: 0,
}

export const unknownMarketObject: VodafoneMarket = {
  slug: UNKNOWN_MARKET_SLUG,
  id: 'none',
  name: 'Unknown',
  creativeXMarket: '',
  iso_code: '',
  shareOfSpendTarget: null,
  ordinal_no: 0,
}

const marketOptionsBySlug = produceKeyedMapping(
  [...vodafoneMarkets, globalMarketObject, unknownMarketObject],
  'slug',
)

export type AnalyticsTableRow = {
  label: string
} & Record<string, string | number | null>

export const analyticsTableColumns: Column<AnalyticsTableRow>[] = [
  {
    key: 'label',
    label: 'Section',
    align: 'left',
  },
  ...vodafoneMarkets.map( market => ({
    key: market.slug,
    label: <MarketDisplayLabel {...market} />,
    align: 'right',
  })) as Column<AnalyticsTableRow>[],
  {
    key: VODAFONE_GLOBAL_SLUG,
    label: (
      <Box display='flex' alignItems='center'>
        <Language style={{fontSize: 18, marginRight: 4}} />
        Global
      </Box>
    ),
    align: 'right',
  },
  {
    key: UNKNOWN_MARKET_SLUG,
    label: (
      <Box display='flex' alignItems='center'>
        <Help style={{fontSize: 18, marginRight: 4}} />
        Unknown
      </Box>
    ),
    align: 'right',
  }
]

export const getPrimaryTableRows = (
  userEvents: AggregatedMarketUserEvent[],
  viewType: ViewType
): AnalyticsTableRow[] => {
  
  const marketPrimaryGrouping: Record<PrimaryApplication, Record<string, string | number | null>> = userEvents.reduce( (acc, event) => {
    if( event.event_type !== 'PAGE_VIEW' ){
      return acc
    }
    acc[event.primary_application] = acc[event.primary_application] || {}
    acc[event.primary_application][event.market] = viewType === 'USERS' ? event.user_count : event.event_count
    return acc
  }, {} as Record<PrimaryApplication, Record<string, number>>)
  
  const orderedKeys: PrimaryApplication[] = intersection(
    primaryApplicationOrder,
    Object.keys(marketPrimaryGrouping) as PrimaryApplication[]
  )
  
  return orderedKeys.map( key => {
    return {
      label: getPrimaryApplicationLabel(key),
      [VODAFONE_GLOBAL_SLUG]: get(marketPrimaryGrouping[key], VODAFONE_GLOBAL_SLUG, null),
      [UNKNOWN_MARKET_SLUG]: get(marketPrimaryGrouping[key], UNKNOWN_MARKET_SLUG, null),
      ...vodafoneMarkets.reduce( (acc, market) => {
        acc[market.slug] = get(marketPrimaryGrouping[key], market.slug, null)
        return acc
      }, {} as Record<string, string | number | null>),
    }
  })
}


type SecondaryAggregation = Record<PrimaryApplication, Partial<Record<SecondaryApplication, Record<string, number>>>>

export const getSecondaryTableRowMapping = (
  userEvents: AggregatedMarketUserEvent[],
  viewType: ViewType
): Record<string, AnalyticsTableRow[]> => {
  
  const marketSecondaryGrouping: SecondaryAggregation = userEvents.reduce( (acc, event) => {
    if( event.event_type !== 'PAGE_VIEW' ){
      return acc
    }
    const secondary = event.secondary_application || 'HOME'
    acc[event.primary_application] = acc[event.primary_application] || {}
    acc[event.primary_application][secondary] = acc[event.primary_application][secondary] || {}
    if( acc[event.primary_application][secondary] ){
      (acc[event.primary_application][secondary] as Record<string, number>)[event.market] = (
        viewType === 'USERS' ? event.user_count : event.event_count
      )
    }
    return acc
  }, {} as SecondaryAggregation)
  
  const mapping = Object.entries(marketSecondaryGrouping).reduce( (acc, [primaryKey, secondaryAggregation]) => {
    const rows = Object.entries(secondaryAggregation).reduce( (acc, [key, data]) => {
      acc.push({
        label: getSecondaryApplicationLabel(key as SecondaryApplication),
        [VODAFONE_GLOBAL_SLUG]: get(data, VODAFONE_GLOBAL_SLUG, null),
        [UNKNOWN_MARKET_SLUG]: get(data, UNKNOWN_MARKET_SLUG, null),
        ...vodafoneMarkets.reduce( (acc, market) => {
          acc[market.slug] = get(data, market.slug, null)
          return acc
        }, {} as Record<string, string | number | null>),
      })
      return acc
    }, [] as AnalyticsTableRow[])
    const sortedRows = sortBy(rows, 'label')
    acc[getPrimaryApplicationLabel(primaryKey as PrimaryApplication)] = sortedRows
    return acc
  }, {} as Record<string, AnalyticsTableRow[]>)

  return mapping
}


const dateIncrementMethodMapping: Record<TimeseriesGranularity, (date: Date, amount: number) => Date> = {
  DAY: addDays,
  WEEK: (date, amount) => addWeeks(startOfWeek(date, { weekStartsOn: 1}), amount),
  MONTH: (date, amount) => addMonths(startOfMonth(date), amount),
  QUARTER: addQuarters,
  CALENDAR_YEAR: addYears,
  FINANCIAL_YEAR: addYears,
}

const getDateLabelsForGranularity = (
  [start, end]: [Date, Date],
  granularity: TimeseriesGranularity = 'DAY'
): string[] => {
  const endTime = end.getTime()
  let current = start
  const values: string[] = []
  const incrementMethod = dateIncrementMethodMapping[granularity]
  while( current.getTime() <= endTime ){
    values.push(format(current, 'yyyy-MM-dd'))
    current = incrementMethod(current, 1)
  }
  return values
}


const getMarkerId = (key: string): string => (
  `marker-id-${key}`
)

export const getMultiDatasetDefs = (datasets: MultiDataset[], markerSize = 3): JSX.Element[] => (
  datasets.map( dataset => (
    <marker
      key={`marker-${dataset.key}`}
      id={getMarkerId(dataset.key)}
      viewBox={`0 0 ${markerSize * 2} ${markerSize * 2}`}
      refX={markerSize}
      refY={markerSize}
      markerWidth={markerSize}
      markerHeight={markerSize}>
      <circle
        cx={markerSize}
        cy={markerSize}
        r={markerSize}
        fill={dataset.color} />
    </marker>
  ))
)


const getGroupedTimeseriesData = ({
  userEvents,
  dateRange,
  granularity,
  groupBy,
  viewType,
  colourScale,
  keyFilter,
}: {
  userEvents: AggregatedMarketUserEvent[]
  dateRange: [Date, Date]
  groupBy: 'MARKET' | 'PRIMARY_APPLICATION' | 'SECONDARY_APPLICATION'
  viewType: ViewType
  colourScale: ScaleOrdinal<string, string>
  keyFilter?: string[]
  granularity?: TimeseriesGranularity
}): Record<string, SVGDatumType[]> => {
  const labels = getDateLabelsForGranularity(dateRange, granularity)
  const groupedRows = userEvents.reduce( (acc, event) => {
    if( event.event_type !== 'PAGE_VIEW' ){
      return acc
    }
    const groupKey = (
      groupBy === 'MARKET' ?
        event.market :
        groupBy === 'PRIMARY_APPLICATION' ?
          event.primary_application :
          event.secondary_application || 'HOME'
    )
    if( keyFilter && !keyFilter.includes(groupKey) ){
      return acc
    }
    acc[groupKey] = acc[groupKey] || {}
    const label = event.date
    acc[groupKey][label] = Number(viewType === 'USERS' ? event.user_count : event.event_count)
    return acc
  }, {} as Record<string, Record<string, number>>)

  return mapValues(
    groupedRows,
    (rowMapping, groupKey): SVGDatumType[] => {
      const color = colourScale(groupKey)
      return labels.map( label => {
        const value = rowMapping[label] || 0
        return {
          label: new Date(label).getTime(),
          value,
          color,
        }
      })
    }
  )
}

export const getAnalyticsTimeseriesDatasets = ({
  userEvents,
  dateRange,
  granularity,
  viewType,
  appTheme,
  groupBy,
}: {
  userEvents: AggregatedMarketUserEvent[]
  dateRange: [Date, Date]
  granularity?: TimeseriesGranularity
  viewType: ViewType
  appTheme: AppTheme
  groupBy: 'MARKET' | 'PRIMARY_APPLICATION' | 'SECONDARY_APPLICATION'
}): MultiDataset[] => {
  const orderedKeys = (
    groupBy === 'MARKET' ? [
      ...vodafoneMarkets.map( m => m.slug ),
      VODAFONE_GLOBAL_SLUG,
      UNKNOWN_MARKET_SLUG,
    ] :
      groupBy === 'PRIMARY_APPLICATION' ?
        primaryApplicationOrder :
        uniq(userEvents.map( e => e.secondary_application || 'HOME')).sort()
  )

  const colourScale = appTheme.chart.getOrdinalColourScale(orderedKeys)

  const groupedRows = getGroupedTimeseriesData({
    userEvents,
    dateRange,
    granularity,
    groupBy,
    viewType,
    colourScale,
  })

  const orderedDatasetKeys = intersection(orderedKeys, Object.keys(groupedRows))

  const datasets: MultiDataset[] = orderedDatasetKeys.map( key => {
    const markerUrl = `url(#${getMarkerId(key)})`
    return {
      key,
      label: (
        groupBy === 'MARKET' ?
          get(marketOptionsBySlug, [key, 'name'], 'Global') :
          groupBy === 'PRIMARY_APPLICATION' ?
            getPrimaryApplicationLabel(key as PrimaryApplication) :
            getSecondaryApplicationLabel(key as SecondaryApplication)
      ),
      data: sortBy(groupedRows[key] || [], 'label'),
      color: colourScale(key),
      svgPathProps: {
        markerStart: markerUrl,
        markerMid: markerUrl,
        markerEnd: markerUrl,
      }
    }
  })

  return datasets
}


export const getCombinedApplicationTimeseriesDataset = ({
  primary,
  secondary,
  primaryApplicationFilter,
  secondaryApplicationFilter,
  dateRange,
  granularity,
  viewType,
  appTheme,
}: {
  primary: AggregatedMarketUserEvent[]
  secondary: AggregatedMarketUserEvent[]
  primaryApplicationFilter: PrimaryApplication[]
  secondaryApplicationFilter: SecondaryApplication[]
  dateRange: [Date, Date]
  granularity?: TimeseriesGranularity
  viewType: ViewType
  appTheme: AppTheme
}): MultiDataset[] => {

  const orderedKeys = [
    ...filteredPrimaryApplications,
    ...filteredSecondaryApplications,
  ]
  const colourScale = appTheme.chart.getOrdinalColourScale(orderedKeys)

  const combinedGroupedRows = {
    ...getGroupedTimeseriesData({
      userEvents: primary,
      dateRange,
      granularity,
      groupBy: 'PRIMARY_APPLICATION',
      keyFilter: primaryApplicationFilter,
      viewType,
      colourScale
    }),
    ...getGroupedTimeseriesData({
      userEvents: secondary,
      dateRange,
      granularity,
      groupBy: 'SECONDARY_APPLICATION',
      keyFilter: secondaryApplicationFilter,
      viewType,
      colourScale
    }),
  }

  const orderedDatasetKeys = intersection(orderedKeys, Object.keys(combinedGroupedRows))

  const combinedLabels: Record<string, string> = {
    ...(primaryApplicationFilter.reduce( (acc, k) => {
      acc[k] = getPrimaryApplicationLabel(k)
      return acc
    }, {} as Record<string, string>)),
    ...(secondaryApplicationFilter.reduce( (acc, k) => {
      acc[k] = getSecondaryApplicationLabel(k)
      return acc
    }, {} as Record<string, string>)),
  }

  return orderedDatasetKeys.map( key => {
    const markerUrl = `url(#${getMarkerId(key)})`
    return {
      key,
      label: combinedLabels[key] || key,
      data: combinedGroupedRows[key],
      color: colourScale(key),
      svgPathProps: {
        markerStart: markerUrl,
        markerMid: markerUrl,
        markerEnd: markerUrl,
      },
    }
  })
}
