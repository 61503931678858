import { differenceInDays } from 'date-fns'

import { DateRangePresetOption } from '@percept/mui'

import { PrimaryApplication, SecondaryApplication } from '@percept/app-components'

import { TimeseriesGranularity } from '@percept/types'


export type ViewType = 'USERS' | 'VIEWS' 

export type ViewSegmentation = 'MARKET' | 'APPLICATION'

export type AnalyticsReducerState = {
  markets: string[]
  primaryApplications: PrimaryApplication[]
  secondaryApplications: SecondaryApplication[]
  viewType: ViewType
  viewSegmentation: ViewSegmentation
  dateRangePreset: DateRangePresetOption
  dateRange: [Date, Date]
  granularity: TimeseriesGranularity
  availableGranularities: TimeseriesGranularity[]
}

type AnalyticsReducerAction = (
  {
    type: 'SET_DATE_RANGE'
    payload: {
      dateRange: [Date, Date]
      dateRangePreset: DateRangePresetOption
      granularity?: TimeseriesGranularity
    }
  } |
  { type: 'SET_GRANULARITY', payload: TimeseriesGranularity } |
  { type: 'SET_VIEW_TYPE', payload: ViewType } |
  { type: 'SET_VIEW_SEGMENTATION', payload: ViewSegmentation } |
  { type: 'SET_MARKETS', payload: string[] } |
  {
    type: 'SET_APPLICATION_FILTERS'
    payload: {
      primaryApplications: PrimaryApplication[]
      secondaryApplications: SecondaryApplication[]
    }
  }
)

const orderedGranularities: TimeseriesGranularity[] = [
  'DAY',
  'WEEK',
  'MONTH',
  'QUARTER',
  'FINANCIAL_YEAR',
  'CALENDAR_YEAR',
]

export const getMaximumGranularity = (
  dateRange: [Date, Date]
): TimeseriesGranularity | null => {
  const dayDiff = Math.abs(differenceInDays(dateRange[0], dateRange[1]))
  let maximumGranularity: TimeseriesGranularity | null = null
  switch(true){
    case dayDiff < 7:
      maximumGranularity = 'DAY'
      break
    case dayDiff < 33:
      maximumGranularity = 'WEEK'
      break
    case dayDiff < 94:
      maximumGranularity = 'MONTH'
      break
    case dayDiff < 365:
      maximumGranularity = 'QUARTER'
      break
  }
  return maximumGranularity
}

export const getRequiredGranularity = (
  dateRange: [Date, Date],
  currentGranularity: TimeseriesGranularity
): TimeseriesGranularity => {
  const maximumGranularity = getMaximumGranularity(dateRange)

  if( maximumGranularity === null ){
    return currentGranularity
  }

  const currentGranularityIndex = orderedGranularities.indexOf(currentGranularity)
  const maximumGranularityIndex = orderedGranularities.indexOf(maximumGranularity)
  if( currentGranularityIndex > maximumGranularityIndex ){
    return maximumGranularity
  }

  return currentGranularity
}

export const getAvailableGranularities = (
  dateRange: [Date, Date],
): TimeseriesGranularity[] => {
  const maximumGranularity = getMaximumGranularity(dateRange)
  if( maximumGranularity === null ){
    return orderedGranularities
  }
  return orderedGranularities.slice(
    0, orderedGranularities.indexOf(maximumGranularity) + 1
  )
}


export const analyticsReducer: React.Reducer<AnalyticsReducerState, AnalyticsReducerAction> = (state, action) => {
  if( action.type === 'SET_DATE_RANGE' ){
    return {
      ...state,
      dateRange: action.payload.dateRange,
      dateRangePreset: action.payload.dateRangePreset,
      granularity: action.payload.granularity || getRequiredGranularity(action.payload.dateRange, state.granularity),
      availableGranularities: getAvailableGranularities(action.payload.dateRange),
    }
  }

  if( action.type === 'SET_GRANULARITY' ){
    return {
      ...state,
      granularity: action.payload,
    }
  }

  if( action.type === 'SET_VIEW_TYPE' ){
    return {
      ...state,
      viewType: action.payload,
    }
  }

  if( action.type === 'SET_VIEW_SEGMENTATION' ){
    return {
      ...state,
      viewSegmentation: action.payload,
    }
  }

  if( action.type === 'SET_MARKETS' ){
    return {
      ...state,
      markets: action.payload,
    }
  }

  if( action.type === 'SET_APPLICATION_FILTERS' ){
    return {
      ...state,
      ...action.payload,
    }
  }

  return state
}
