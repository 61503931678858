module.exports = {
  outputPath: '/assets',
  assetDirectory: '/assets',
  assets: {
    // News assets
    // 'tiktok-may-23': {
    //   filename: 'tiktok-may-23',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Vodafone TikTok Update May 2023',
    // },
    // 'brand-dashboard-exco-apr-23': {
    //   filename: 'brand-dashboard-exco-apr-23',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Brand Dashboard ExCo',
    // },
    // 'brand-dashboard-exco-sep-23': {
    //   filename: 'brand-dashboard-exco-sep-23',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Brand ExCo September 2023',
    // },
    // 'brand-safety-news-sep-23': {
    //   filename: 'brand-safety-news-sep-23',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Brand Safety News September 2023',
    // },
    // 'social-media-playbook': {
    //   filename: 'social-media-playbook',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Social Media Creative Best Practice',
    // },
    // 'gen-ai-101': {
    //   filename: 'gen-ai-101',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Generative AI 101',
    // },
    // 'gen-ai-creative-and-media': {
    //   filename: 'gen-ai-creative-and-media',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Generative AI Creative & Media',
    // },
    // 'gen-ai-adtech-partners': {
    //   filename: 'gen-ai-adtech-partners',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Generative AI AdTech Partner Capabilities',
    // },
    // BGP assets
    // cut back to break through guidance
    'bgp-cut-back-enhance': {
      filename: 'bgp-cut-back-enhance',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Cut Back to Break Through - ENHANCE',
    },
    'bgp-cut-back-evangelize': {
      filename: 'bgp-cut-back-evangelize',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Cut Back to Break Through - EVANGELIZE',
    },
    'bgp-cut-back-scale': {
      filename: 'bgp-cut-back-scale',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Cut Back to Break Through - SCALE',
    },
    'bgp-cut-back-sustain': {
      filename: 'bgp-cut-back-sustain',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Cut Back to Break Through - SUSTAIN',
    },
    // invest for impact guidance
    'bgp-invest-for-impact-enhance': {
      filename: 'bgp-invest-for-impact-enhance',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Invest for Impact - ENHANCE',
    },
    'bgp-invest-for-impact-evangelize': {
      filename: 'bgp-invest-for-impact-evangelize',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Invest for Impact - EVANGELIZE',
    },
    'bgp-invest-for-impact-scale': {
      filename: 'bgp-invest-for-impact-scale',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Invest for Impact - SCALE',
    },
    'bgp-invest-for-impact-sustain': {
      filename: 'bgp-invest-for-impact-sustain',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Invest for Impact - SUSTAIN',
    },
    // planning guidance
    'bgp-planning-for-growth-1.0': {
      filename: 'bgp-planning-for-growth-1.0',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Planning for Growth 1.0',
    },
    'bgp-planning-for-growth-2.0': {
      filename: 'bgp-planning-for-growth-2.0',
      extension: 'pdf',
      type: 'OPEN',
      displayFilename: 'Planning for Growth 2.0',
    },
    // MMM assets
    // 'mmm-de-08-2023': {
    //   filename: 'mmm-de-08-2023',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Directional Media Recommendation - Germany - August 2023',
    // },
    'mmm-al-10-2024': {
      filename: 'mmm-al-10-2024',
      extension: 'pptx',
      type: 'DOWNLOAD',
      displayFilename: 'Directional Media Recommendation - Albania - October 2024',
    },
    'mmm-cz-10-2024': {
      filename: 'mmm-cz-10-2024',
      extension: 'pptx',
      type: 'DOWNLOAD',
      displayFilename: 'Directional Media Recommendation - Czechia - October 2024',
    },
    'mmm-de-10-2024': {
      filename: 'mmm-de-10-2024',
      extension: 'pptx',
      type: 'DOWNLOAD',
      displayFilename: 'Directional Media Recommendation - Germany - October 2024',
    },
    'mmm-gr-10-2024': {
      filename: 'mmm-gr-10-2024',
      extension: 'pptx',
      type: 'DOWNLOAD',
      displayFilename: 'Directional Media Recommendation - Greece - October 2024',
    },
    'mmm-ie-10-2024': {
      filename: 'mmm-ie-10-2024',
      extension: 'pptx',
      type: 'DOWNLOAD',
      displayFilename: 'Directional Media Recommendation - Ireland - October 2024',
    },
    'mmm-pt-10-2024': {
      filename: 'mmm-pt-10-2024',
      extension: 'pptx',
      type: 'DOWNLOAD',
      displayFilename: 'Directional Media Recommendation - Portugal - October 2024',
    },
    'mmm-ro-10-2024': {
      filename: 'mmm-ro-10-2024',
      extension: 'pptx',
      type: 'DOWNLOAD',
      displayFilename: 'Directional Media Recommendation - Romania - October 2024',
    },
    'mmm-tr-10-2024': {
      filename: 'mmm-tr-10-2024',
      extension: 'pptx',
      type: 'DOWNLOAD',
      displayFilename: 'Directional Media Recommendation - Turkey - October 2024',
    },
    'mmm-uk-10-2024': {
      filename: 'mmm-uk-10-2024',
      extension: 'pptx',
      type: 'DOWNLOAD',
      displayFilename: 'Directional Media Recommendation - United Kingdom - October 2024',
    },
    // Pathways assets
    // 'pathways-de-2023': {
    //   filename: 'pathways-de-2023',
    //   extension: 'zip',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - Germany - 2023',
    // },
    // 'pathways-eg-2023': {
    //   filename: 'pathways-eg-2023',
    //   extension: 'zip',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - Egypt - 2023',
    // },
    // 'pathways-es-2023': {
    //   filename: 'pathways-es-2023',
    //   extension: 'zip',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - Spain - 2023',
    // },
    // 'pathways-gr-2023': {
    //   filename: 'pathways-gr-2023',
    //   extension: 'zip',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - Greece - 2023',
    // },
    // 'pathways-ie-2023': {
    //   filename: 'pathways-ie-2023',
    //   extension: 'zip',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - Ireland - 2023',
    // },
    // 'pathways-it-2023': {
    //   filename: 'pathways-it-2023',
    //   extension: 'zip',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - Italy - 2023',
    // },
    // 'pathways-pt-2023': {
    //   filename: 'pathways-pt-2023',
    //   extension: 'zip',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - Portugal - 2023',
    // },
    // 'pathways-tr-2023': {
    //   filename: 'pathways-tr-2023',
    //   extension: 'zip',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - Turkey - 2023',
    // },
    // 'pathways-uk-2023': {
    //   filename: 'pathways-uk-2023',
    //   extension: 'zip',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - United Kingdom - 2023',
    // },
    'pathways-al-2024': {
      filename: 'pathways-al-2024',
      extension: 'pdf',
      type: 'DOWNLOAD',
      displayFilename: 'Pathways - Albania - 2024',
    },
    // 'pathways-cz-2024': {
    //   filename: 'pathways-cz-2024',
    //   extension: 'pdf',
    //   type: 'DOWNLOAD',
    //   displayFilename: 'Pathways - Czechia - 2024',
    // },
    'pathways-de-2024': {
      filename: 'pathways-de-2024',
      extension: 'pdf',
      type: 'DOWNLOAD',
      displayFilename: 'Pathways - Germany - 2024',
    },
    'pathways-gr-2024': {
      filename: 'pathways-gr-2024',
      extension: 'pdf',
      type: 'DOWNLOAD',
      displayFilename: 'Pathways - Greece - 2024',
    },
    'pathways-ie-2024': {
      filename: 'pathways-ie-2024',
      extension: 'pdf',
      type: 'DOWNLOAD',
      displayFilename: 'Pathways - Ireland - 2024',
    },
    'pathways-pt-2024': {
      filename: 'pathways-pt-2024',
      extension: 'pdf',
      type: 'DOWNLOAD',
      displayFilename: 'Pathways - Portugal - 2024',
    },
    'pathways-ro-2024': {
      filename: 'pathways-ro-2024',
      extension: 'pdf',
      type: 'DOWNLOAD',
      displayFilename: 'Pathways - Italy - 2024',
    },
    'pathways-tr-2024': {
      filename: 'pathways-tr-2024',
      extension: 'pdf',
      type: 'DOWNLOAD',
      displayFilename: 'Pathways - Turkey - 2024',
    },
    'pathways-uk-2024': {
      filename: 'pathways-uk-2024',
      extension: 'pdf',
      type: 'DOWNLOAD',
      displayFilename: 'Pathways - United Kingdom - 2024',
    },
    'pathways-za-2024': {
      filename: 'pathways-za-2024',
      extension: 'pdf',
      type: 'DOWNLOAD',
      displayFilename: 'Pathways - South Africa - 2024',
    },
  },
}
