import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router'

import qs from 'query-string'

import {
  AppTheme,
  Box,
  Card,
  CardStrip,
  Collapse,
  Grid,
  Typography,
  useAppTheme,
  makeAppStyles,
  ClassNameMap,
  PercentageBlob,
  RoundedPlainTextButton,
  ProviderLogo,
  Divider,
  Chip,
  SecondaryIconTooltip,
} from '@percept/mui'

import { Add, ArrowDropDown, ArrowDropUp, CheckOutlined, Close, WarningOutlined } from '@percept/mui/icons'

import { DSLRenderer } from './DSLComponents'

import { InsightOutputUnitDetail } from './InsightOutputUnitDetail'

import {
  InsightUnitsByImportance,
  useFilteredMetricMetadata,
  useNavigation,
  useReportSeriesReportEntity,
  useReportSeriesReportPayload,
} from '@percept/hooks'

import {
  DerivedInsightImportance,
  DimensionType,
  InsightOutputUnit,
  InsightVertical,
  Nullable,
  ReportParams,
  ReportProvider,
  ReportProviderV2,
  SeriesParams,
} from '@percept/types'

import { flatten, get, intersection, sum, xor } from 'lodash-es'

import { avg, coerceReportProvider, deslugify } from '@percept/utils'

import { MetricDetailDialog } from './MetricDetailDialog'

import { automaticPrecisionPercentageFormatter, percentageFormatter } from '@percept/mui/charts'


type InsightUnitClassName = (
  'sectionToolbar' | 'sectionIcon' | 'sectionHealth' | 'sectionToolbarChip' | 'sectionToolbarChipIcon' |
  'sectionExplainer' | 'sectionExplainerIcon' | 'card' | 'cardContent' | 'titleContent' | 'textContent' |
  'title' | 'text' | 'chip' | 'chipWrapper'
)

const useInsightUnitStyles = makeAppStyles<{}, InsightUnitClassName>( theme => ({
  sectionToolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  sectionIcon: {
    marginRight: theme.spacing(1),
  },
  sectionHealth: {
    marginLeft: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold,
  },
  sectionExplainer: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    fontSize: 'inherit',
  },
  sectionExplainerIcon: {
    fontSize: '18px important!',
  },
  card: {
    minHeight: '100%',
    cursor: 'pointer',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1.5),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  chipWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  sectionToolbarChip: {
    color: 'inherit',
    borderColor: 'inherit',
  },
  sectionToolbarChipIcon: {
    color: 'inherit',
  },
  chip: {
    color: (
      theme.palette.type === 'dark' ?
        theme.palette.secondary.light :
        theme.palette.secondary.main
    ),
    borderColor: (
      theme.palette.type === 'dark' ?
        theme.palette.secondary.light :
        theme.palette.secondary.main
    ),
    marginRight: theme.spacing(2),
    height: 20,
    fontSize: 12,
    padding: 4,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  text: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
  }
}))


const InsightUnitComponent = ({
  insightUnit,
  appTheme,
  classes,
  onClick,
}: {
  insightUnit: InsightOutputUnit
  appTheme: AppTheme
  classes: ClassNameMap<InsightUnitClassName>
  onClick: () => void
}): JSX.Element => {
  const { health } = insightUnit
  const healthColor = (
    health !== null ?
      appTheme.chart.healthColourScale(health) :
      null
  )
  const contrastColor = healthColor ? appTheme.palette.getContrastText(healthColor) : undefined
  return (
    <Card className={classes.card} onClick={onClick}>
      <CardStrip color='health' health={health} />
      <div className={classes.cardContent}>
        <div className={classes.titleContent}>
          { healthColor && health !== null && (
            <PercentageBlob
              value={health * 100}
              size={36}
              fontSize={12}
              color={healthColor}
              textColor={contrastColor} />
          )}
          <Typography variant='h6' className={classes.title}>
            { insightUnit.title }
          </Typography>
        </div>
        <div className={classes.textContent}>
          <div className={classes.text}>
            <DSLRenderer
              text={insightUnit.text}
              showRecommendations={false}
              showEntityCountChip={false}
              currency={insightUnit.currency}
              appTheme={appTheme} />
          </div>
          { !!(insightUnit.vertical || insightUnit.effective_potential_efficiency_rate !== null) && (
            <div className={classes.chipWrapper}>
              { insightUnit.vertical && (
                <Chip
                  classes={{root: classes.chip}}
                  variant='outlined'
                  color='secondary'
                  size='small'
                  label={deslugify(insightUnit.vertical)} />
              )}
              {/* { insightUnit.effective_potential_efficiency_rate !== null && (
                <Chip
                  classes={{root: classes.chip}}
                  variant='outlined'
                  icon={
                    insightUnit.effective_potential_efficiency_rate ?
                      <WarningOutlined /> :
                      <CheckOutlined /> 
                  }
                  color='secondary'
                  size='small'
                  label={
                    `Wastage ${automaticPrecisionPercentageFormatter(insightUnit.effective_potential_efficiency_rate * 100)}`
                  } />
              )} */}
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}


const CollapsibleInsightUnits = ({
  title,
  explainer,
  collapsed,
  toggleCollapse,
  insightUnits,
  onInsightUnitClick,
  appTheme,
  classes,
}: {
  title: React.ReactNode
  explainer?: React.ReactNode
  collapsed: boolean
  toggleCollapse: () => void
  onInsightUnitClick: (insightUnit: InsightOutputUnit) => void
  insightUnits: InsightOutputUnit[]
  appTheme: AppTheme
  classes: ClassNameMap<InsightUnitClassName>
}): JSX.Element => {
  const healthValues: number[] = insightUnits.map( i => i.health ).filter( h => h !== null )
  const overallHealth = healthValues.length ? avg(healthValues) : null
  const backgroundColor = (
    overallHealth === null ?
      appTheme.palette.background.paper :
      appTheme.chart.healthColourScale(overallHealth)
  )
  const textColor = appTheme.palette.getContrastText(backgroundColor)
  const displayHealth = overallHealth === null ? null : (
    percentageFormatter(Math.round(overallHealth * 100))
  )
  // const wastageValues: number[] = insightUnits.map( i => i.effective_potential_efficiency_rate ).filter( e => e !== null )
  // const overallWastage = wastageValues.length ? sum(wastageValues) : null
  // const displayWastage = overallWastage === null ? null : (
  //   <Chip
  //     variant='outlined'
  //     size='small'
  //     classes={{
  //       root: classes.sectionToolbarChip,
  //       icon: classes.sectionToolbarChipIcon,
  //     }}
  //     icon={overallWastage === 0 ? <CheckOutlined color='inherit' /> : <WarningOutlined color='inherit' />}
  //     label={`Wastage ${percentageFormatter(overallWastage * 100)}`} />
  // )
  const IconComponent = collapsed ? ArrowDropUp : ArrowDropDown
  return (
    <div>
      <div
        className={classes.sectionToolbar}
        style={{
          backgroundColor,
          color: textColor,
        }}
        onClick={(): void => {
          if( insightUnits.length ){
            toggleCollapse()
          }
        }}>
        <IconComponent className={classes.sectionIcon} />
        <Typography variant='h5'>{ title }</Typography>
        { displayHealth && <span className={classes.sectionHealth}>{displayHealth}</span> }
        {/* { displayWastage && <span className={classes.sectionHealth}>{displayWastage}</span> } */}
        { explainer && (
          <div className={classes.sectionExplainer}>
            <SecondaryIconTooltip
              IconProps={{
                className: classes.sectionExplainerIcon,
              }}
              title={explainer} />
          </div>
        )}
      </div>
      <Collapse in={!collapsed}>
        <Box mt={4} mb={5}>
          <Grid container spacing={4}>
            { insightUnits.map( insightUnit => (
              <Grid key={insightUnit.id} item xs={12} md={6} lg={4} xl={3}>
                <InsightUnitComponent
                  insightUnit={insightUnit}
                  onClick={(): void => {
                    onInsightUnitClick(insightUnit)
                  }}
                  appTheme={appTheme}
                  classes={classes} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Collapse>
    </div>
  )
}


const orderedVerticals: InsightVertical[] = [
  'SEARCH', 'PERFORMANCE_MAX', 'VIDEO'
]

const getAvailableVerticals = (insightUnits: InsightOutputUnit[]): InsightVertical[] => (
  intersection(
    orderedVerticals,
    insightUnits.map( insight => insight.vertical ).filter( v => v !== null ),
  )
)

const getAllVerticals = (insightsByImportance: InsightUnitsByImportance): InsightVertical[] => (
  intersection(
    orderedVerticals,
    flatten(Object.values(insightsByImportance).map(getAvailableVerticals)),
  )
)

const findInsightByDefinitionId = (
  insightsByImportance: InsightUnitsByImportance,
  definitionId: string
): InsightOutputUnit | null => {
  let match: InsightOutputUnit | null = null
  for( const insights of Object.values(insightsByImportance) ){
    match = insights.find( i => i.definition_id === definitionId ) || null
    if( match ){
      break
    }
  }
  return match
}

const orderedImportanceRatings: DerivedInsightImportance[] = [
  'CRITICAL', 'PRIORITY_WITH_WASTAGE', 'PRIORITY_WITHOUT_WASTAGE', 'HOUSEKEEPING'
]

const importanceLabels: Record<DerivedInsightImportance, string> = {
  CRITICAL: 'Critical Alerts',
  PRIORITY_WITH_WASTAGE: 'Key Tasks',
  PRIORITY_WITHOUT_WASTAGE: 'Secondary Tasks',
  HOUSEKEEPING: 'Housekeeping',
}

const importanceExplainers: Record<DerivedInsightImportance, React.ReactNode> = {
  CRITICAL: (
    <>
      If any of these insights are not 100%, then there's a critical issue with your accounts that needs attention immediately
    </>
  ),
  PRIORITY_WITH_WASTAGE: (
    <>
      Insights are sorted by a combination of health of the insight and the estimated potential wastage associated with the insight,
      so that the most important to deal with are always at the top of the section
    </>
  ),
  PRIORITY_WITHOUT_WASTAGE: (
    <>
      These insights have a less direct effect on performance, but should be actioned to ensure best practice is followed
    </>
  ),
  HOUSEKEEPING: (
    <>
      These insights might not have a direct impact on performance, but making sure that you keep your accounts well set up
      makes it easier to manage them, and saves time in the long run
    </>
  )
}

const useStyles = makeAppStyles( theme => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContentRight: {
    marginLeft: 'auto',
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  secondaryTitleContent: {
    marginLeft: theme.spacing(2),
  },
  filterContainer: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  filterControl: {
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    }
  },
}))

export type InsightsByImportanceProps = (
  SeriesParams
  & Nullable<ReportParams>
  & Partial<Nullable<{ result_id: string }>>
  & {
    provider: ReportProviderV2
    insightsByImportance: InsightUnitsByImportance
    secondaryTitleContent?: React.ReactNode
    titleContentRight?: React.ReactNode
  }
)

export const InsightsByImportance = ({
  series_id,
  report_id,
  result_id,
  provider,
  insightsByImportance,
  secondaryTitleContent,
  titleContentRight,
}: InsightsByImportanceProps): JSX.Element => {

  const location = useLocation()

  const qsParams = qs.parse(location.search)

  const navigate = useNavigation()

  const availableVerticals = getAllVerticals(insightsByImportance)

  const [verticalFilters, setVerticalFilters] = useState(availableVerticals)

  const [report] = useReportSeriesReportPayload({
    series_id,
    report_id,
    ...(result_id && { result_id }),
  })

  const entity_type = get(report.data, 'root_entity_type', null)
  const entity_id = get(report.data, 'root_entity_id', null)

  const [reportEntity] = useReportSeriesReportEntity({
    series_id,
    report_id,
    entity_type,
    entity_id,
    ...(result_id && { result_id }),
  })

  // Load this eagerly so we're not waiting for metrics and example tables
  useFilteredMetricMetadata({
    provider: coerceReportProvider(provider) as ReportProvider,
  })

  useEffect(() => {
    setVerticalFilters(getAllVerticals(insightsByImportance))
  }, [insightsByImportance])

  const activeInsight = (
    qsParams.insight ?
      findInsightByDefinitionId(insightsByImportance, qsParams.insight as string) :
      null
  )

  const activeMetricId: string | null = (qsParams.metric as string) || null
  const activeMetricSegment: string | undefined = (qsParams.segment as string | undefined)
  const activeMetricDimension: DimensionType = (qsParams.dimension as DimensionType) || 'count'

  const [collapsedSections, setCollapsedSections] = useState<Partial<Record<DerivedInsightImportance, boolean>>>({})

  const appTheme = useAppTheme()

  const classes = useStyles()

  const insightUnitClasses = useInsightUnitStyles()

  return (
    <>
      <div className={classes.flexCenter}>
        <ProviderLogo
          size={1.75}
          units='rem'
          provider={provider} />

        { secondaryTitleContent && (
          <div className={classes.secondaryTitleContent}>{secondaryTitleContent}</div>
        )}

        { !!availableVerticals.length && (
          <div className={classes.filterContainer}>
            { availableVerticals.map( vertical => {
              const isSelected = verticalFilters.includes(vertical)
              return (
                <RoundedPlainTextButton
                  key={vertical}
                  className={classes.filterControl}
                  variant='contained'
                  size='small'
                  color={isSelected ? 'secondary' : 'default'}
                  startIcon={
                    isSelected ? <Close /> : <Add />
                  }
                  onClick={(): void => {
                    setVerticalFilters( prev => xor(prev, [vertical]))
                  }}>
                  { deslugify(vertical) }
                </RoundedPlainTextButton>
              )
            })}
          </div>
        )}

        { titleContentRight && (
          <div className={classes.titleContentRight}>
            { titleContentRight }
          </div>
        )}
      </div>

      <Divider className={classes.divider} />

      <Grid container spacing={3} direction='column'>
        { orderedImportanceRatings.map( importance => {
          const filteredInsights = (insightsByImportance[importance] || []).filter( i => (
            i.vertical === null || verticalFilters.includes(i.vertical)
          ))
          if( !filteredInsights.length ){
            return null
          }
          return (
            <Grid key={importance} item xs={12}>
              <CollapsibleInsightUnits
                appTheme={appTheme}
                classes={insightUnitClasses}
                title={importanceLabels[importance]}
                explainer={importanceExplainers[importance]}
                insightUnits={filteredInsights}
                collapsed={filteredInsights.length === 0 || !!collapsedSections[importance]}
                toggleCollapse={(): void => {
                  setCollapsedSections( prev => ({
                    ...prev, [importance]: !prev[importance],
                  }))
                }}
                onInsightUnitClick={(insightUnit): void => {
                  navigate(location.pathname, {
                    replace: true,
                    search: {
                      insight: insightUnit.definition_id,
                    }
                  })
                }} />
            </Grid>
          )
        })}
      </Grid>

    { activeInsight && reportEntity.data && (
      <InsightOutputUnitDetail
        insightUnit={activeInsight}
        reportEntity={reportEntity.data}
        setActiveMetric={(metric): void => {
          navigate(location.pathname, {
            replace: true,
            search: {
              metric: metric.metric_id,
              ...(metric.segment && {
                segment: metric.segment.label,
              })
            }
          })
        }}
        provider={provider}
        DialogProps={{
          onClose: (): void => {
            navigate(location.pathname, {
              replace: true,
              search: '',
            })
          }
        }}
        series_id={series_id} />
    )}

    { activeMetricId && reportEntity.data && (
      <MetricDetailDialog
        provider={coerceReportProvider(provider) as ReportProvider}
        series_id={series_id}
        report_id={report_id}
        entity_type={entity_type}
        entity_id={entity_id}
        metric_id={activeMetricId}
        dimension={activeMetricDimension}
        segment={activeMetricSegment}
        onSegmentChange={(segment): void => {
          if( activeMetricId ){
            navigate(location.pathname, {
              replace: true,
              search: {
                metric: activeMetricId,
                dimension: activeMetricDimension,
                segment,
              }
            })
          }
        }}
        onDimensionChange={(e, dimension): void => {
          if( activeMetricId ){
            navigate(location.pathname, {
              replace: true,
              search: {
                metric: activeMetricId,
                segment: activeMetricSegment,
                dimension,
              }
            })
          }
        }}
        reportMetric={reportEntity.data.metrics[activeMetricId]}
        DialogProps={{
          onClose: (): void => {
            navigate(location.pathname, {
              replace: true,
              search: '',
            })
          }
        }} />
    )}

    </>
  )
}
