import { vodafonePalette } from '@percept/mui'

import { MultiDataset } from '@percept/mui/charts'
import { avg } from '@percept/utils'

import { capitalize } from 'lodash-es'

import { vodafoneMarkets } from 'vodafoneMarkets'


type PathwaysSegment = 'passive' | 'exploration' | 'experience' | 'loyalty'

export type PathwaysData = Record<PathwaysSegment, number>

export const pathwaysDataByIsoCode: Record<string, PathwaysData> = {
  AL: {
    passive: 7,
    exploration: 13,
    experience: 8,
    loyalty: 72,
  },
  CZ: {
    passive: 11,
    exploration: 12,
    experience: 9,
    loyalty: 68,
  },
  DE: {
    passive: 16,
    exploration: 20,
    experience: 9,
    loyalty: 55,
  },
  GR: {
    passive: 13,
    exploration: 18,
    experience: 9,
    loyalty: 60,
  },
  IE: {
    passive: 11,
    exploration: 12,
    experience: 9,
    loyalty: 68,
  },
  PT: {
    passive: 9,
    exploration: 16,
    experience: 8,
    loyalty: 67,
  },
  RO: {
    passive: 7,
    exploration: 11,
    experience: 9,
    loyalty: 73,
  },
  TR: {
    passive: 22,
    exploration: 25,
    experience: 9,
    loyalty: 43,
  },
  UK: {
    passive: 16,
    exploration: 20,
    experience: 12,
    loyalty: 52,
  },
  ZA: {
    passive: 13,
    exploration: 18,
    experience: 11,
    loyalty: 58,
  },
}

export const pathwaysSegmentOrder: PathwaysSegment[] = [
  'passive', 'exploration', 'experience', 'loyalty'
]

export const globalAveragePathwaysData: PathwaysData = pathwaysSegmentOrder.reduce( (acc, segment) => {
  const average = avg(Object.values(pathwaysDataByIsoCode).map( d => d[segment]) )
  acc[segment] = average
  return acc
}, {} as PathwaysData)


export const pathwaysDatasetColours: Record<PathwaysSegment, string> = {
  passive: vodafonePalette.springGreen,
  exploration: vodafonePalette.aquaBlue,
  experience: vodafonePalette.freshOrange,
  loyalty: vodafonePalette.redViolet,
}

export const pathwaysMarkets = vodafoneMarkets.filter( m => !!pathwaysDataByIsoCode[m.iso_code] )

export const pathwaysDatasets: MultiDataset[] = pathwaysSegmentOrder.map( segment => {
  return {
    key: segment,
    label: capitalize(segment),
    color: pathwaysDatasetColours[segment],
    data: [
      {
        value: globalAveragePathwaysData[segment],
        label: 'Global Avg.',
        color: pathwaysDatasetColours[segment],
      },
      ...pathwaysMarkets.map( m => ({
        value: pathwaysDataByIsoCode[m.iso_code][segment],
        label: m.name,
        color: pathwaysDatasetColours[segment],
      }))
    ],
  }
})
