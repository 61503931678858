import React, { useState } from 'react'

import {
  Box,
  ButtonPopover,
  ButtonPopoverProps,
  CheckboxGroup,
  Divider,
  RoundedPlainTextButton,
} from '@percept/mui'
import { ArrowDropDown } from '@percept/mui/icons'

import { MarketDisplayLabel } from 'components/MarketDisplay'

import { VodafoneMarket } from 'vodafoneMarkets'


export type MultiMarketSelectProps = {
  value: string[] | null
  options: (VodafoneMarket & { labelOverride?: JSX.Element | null })[]
  onChange: (value: string[]) => void
  ButtonPopoverProps?: Partial<Omit<ButtonPopoverProps, 'children'>>
  valueKey?: 'id' | 'slug' | 'iso_code'
}


export const MultiMarketSelect = ({
  value,
  valueKey = 'id',
  options,
  onChange,
  ButtonPopoverProps,
}: MultiMarketSelectProps): JSX.Element => {

  const [localValue, setLocalValue] = useState<string[]>(value || [])

  const areFiltersApplied = (
    (value || []).sort().join() === localValue.sort().join()
  )

  const isEmptyValue = localValue.length === 0

  return (
    <ButtonPopover
      variant='contained'
      endIcon={<ArrowDropDown />}
      ButtonComponent={RoundedPlainTextButton}
      buttonContent='Select Markets'
      onClose={(reason): void => {
        if( reason ){
          setLocalValue(value || [])
        }
      }}
      {...ButtonPopoverProps}>
      { ({ onClose }): JSX.Element => (
        <Box p={2}>
          <Box ml={0}>
            <CheckboxGroup
              name='Markets'
              value={localValue}
              options={
                options.map( option => ({
                  value: option[valueKey],
                  label: option.labelOverride || <MarketDisplayLabel {...option} />,
                }))
              }
              onChange={setLocalValue} />
          </Box>
          <Box maxWidth='100%' flexBasis='100%' mt={1} px={0}>
            <Divider />
          </Box>
          <Box pt={2} textAlign='right'>
            <RoundedPlainTextButton
              variant='contained'
              color='secondary'
              size='small'
              disabled={areFiltersApplied || isEmptyValue}
              onClick={(): void => {
                onChange(localValue)
                onClose()
              }}>
              { isEmptyValue ? 'Select A Market' : areFiltersApplied ? 'Filters Applied' : 'Apply Filters'}
            </RoundedPlainTextButton>
          </Box>
        </Box>
      )}
    </ButtonPopover>
  )
}
