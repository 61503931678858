import React from 'react'

import { useSpring } from 'react-spring'

import { AnimatedPercentage, Box, Grid, Typography, makeAppStyles } from '@percept/mui'

import pathwaysGraphImage from '../../images/backgrounds/pathways-graph-bg.png'

import { PathwaysData } from './graphData'


const useStyles = makeAppStyles( theme => ({
  container: {
    padding: theme.spacing(3),
    minHeight: 550,
    background: `url(${pathwaysGraphImage}) no-repeat center center`,
    backgroundSize: 'cover',
  },
  gridItem: {
    width: '100%',
    minHeight: 550,
    textAlign: 'center',
    color: '#fff'
  },
  centralSubtitle: {
    fontSize: 20,
    marginTop: theme.spacing(1),
    color: '#fff',
    width: '100%'
  },
  leftGraphText: {
    minWidth: '100%',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '50%'
  },
  rightGraphText: {
    minWidth: '100%',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    marginRight: '50%'
  },
  percentage: {
    marginRight: '0.2em',
  },
  whiteText: {
    color: '#fff',
    width: '100%'
  },
  img: {
    width: '100%',
    height: '100%'
  },
  gridContainer:{
    height: '100%',
    width: '100%',
    minHeight: 550
  }
}))



export type PathwaysJourneyProps = {
  data: PathwaysData
}

export const PathwaysJourney = ({ data }: PathwaysJourneyProps): JSX.Element => {

  const classes = useStyles()

  const transitions = useSpring({
    ...data,
    to: {
      ...data,
    },
  })

  return (
    <div className={classes.container}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={3} className={classes.gridItem}>
          <Typography variant='h3' className={classes.leftGraphText}>
            <AnimatedPercentage className={classes.percentage} value={transitions.passive} />
            Passive
          </Typography>
          <Typography variant='h3' className={classes.leftGraphText}>
            <AnimatedPercentage className={classes.percentage} value={transitions.exploration} />
            Exploration
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
            <Typography variant="h3" className={classes.whiteText}>
              Pathways Journey Stages
            </Typography>
            <Typography variant="h5" className={classes.centralSubtitle}>
              Global Outlook
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.gridItem}>
          <Typography variant='h3' className={classes.rightGraphText}>
            <AnimatedPercentage className={classes.percentage} value={transitions.experience} />
            Experience
          </Typography>
          <Typography variant='h3' className={classes.rightGraphText}>
            <AnimatedPercentage className={classes.percentage} value={transitions.loyalty} />
            Loyalty
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
