import React from 'react'

import { Box, makeAppStyles } from '@percept/mui'

import { Link } from 'react-router-dom'

import { DefaultMediaWizardLayout } from './DefaultMediaWizardLayout'


const useStyles = makeAppStyles( theme => ({
  link: {
    cursor: 'pointer',
    color: 'inherit',
    textDecoration: 'underline solid',
    textUnderlineOffset: '5px',
    marginLeft: '0.25em',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: `underline solid ${theme.palette.primary.main}`,
    },
    transition: theme.transitions.create(['color', 'text-decoration']),
  },
}))


export const PageNotFound = (): JSX.Element => {
  const classes = useStyles()
  return (
    <DefaultMediaWizardLayout>
      <Box display='flex' flexDirection='column' alignItems='center' padding={10}>
        <Box mb={10} fontSize={52} fontWeight={700}>
          Page Not Found
        </Box>
        {/* <Box fontSize={24} mb={6}>
          We&apos;ve made a note of it and we&apos;ll try and get it fixed quickly.
        </Box> */}
        <Box fontSize={24}>
          Try going
          <Link
            to='/wizard/home'
            className={classes.link}>
            back to the home screen
          </Link>
        </Box>
      </Box>
    </DefaultMediaWizardLayout>
  )
}

export default PageNotFound
