
export const marketPathwaysAssetMap: Record<string, string> = {
  AL: 'pathways-al-2024',
  CZ: 'pathways-cz-2024',
  DE: 'pathways-de-2024',
  GR: 'pathways-gr-2024',
  IE: 'pathways-ie-2024',
  PT: 'pathways-pt-2024',
  RO: 'pathways-ro-2024',
  TR: 'pathways-tr-2024',
  UK: 'pathways-uk-2024',
  ZA: 'pathways-za-2024',
}
